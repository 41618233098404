// employee-hour-balance.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { TimeRecordingService } from '../../services/time-recording.service';
import { EmployeeHourBalanceDto } from '../../model/employee-hour-balance-dto';
import { BalancePeriod } from 'src/app/core/services/const';

@Component({
  selector: 'app-employee-hour-balance',
  templateUrl: './employee-hour-balance.component.html',
  styleUrls: ['./employee-hour-balance.component.css'],
})
export class EmployeeHourBalanceComponent implements OnInit {
  @Input() employeeId: number;
  @Input() companyId: number;
  @Input() period: BalancePeriod = BalancePeriod.Month;
  @Input() year?: number;
  @Input() month?: number;
  @Input() week?: number;

  hourBalance: EmployeeHourBalanceDto;

  BalancePeriod = BalancePeriod; // um es im Template verwenden zu können

  constructor(private timeRecordingService: TimeRecordingService) {}

  ngOnInit(): void {
    this.timeRecordingService.employeeHourBalance$.subscribe((data) => {
      if (data) {
        this.hourBalance = data;
      }
    });

    // Rufe die Daten vom Backend ab
    this.timeRecordingService.getEmployeeHourBalance(
      this.period,
      this.year,
      this.month,
      this.week
    );
  }

  onPeriodSelect(selectedPeriod: BalancePeriod) {
    this.period = selectedPeriod;
    this.timeRecordingService.getEmployeeHourBalance(
      this.period,
      this.year,
      this.month,
      this.week
    );
  }
}
