import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { DailyAvailability } from '../../model/daily-availability';
import { AvailabilityService } from '../../services/availability.service';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';

@Component({
  selector: 'app-add-availability',
  templateUrl: './add-availability.component.html',
  styleUrls: ['./add-availability.component.css'],
})
export class AddAvailabilityComponent implements OnInit {
  formModel: DailyAvailability = {
    Id: 0,
    Weekday: '-1',
    Start: null,
    End: null,
    isWeekendVisible: true,
  };

  minEndTime =
    this.companySetting.CompanySettings.CalendarOptions.BusinessHoursStartTime;

  minTime =
    this.companySetting.CompanySettings.CalendarOptions.BusinessHoursStartTime;

  absenceTypeList = [
    { id: -1, label: 'please select the day' },
    { id: 0, label: 'Sunday' },
    { id: 1, label: 'Monday' },
    { id: 2, label: 'Tuesday' },
    { id: 3, label: 'Wednesday' },
    { id: 4, label: 'Thursday' },
    { id: 5, label: 'Friday' },
    { id: 6, label: 'Saturday' },
  ];
  Title = this.translate.instant('Add New Availability');
  buttonTitle = this.translate.instant('Save');
  constructor(
    public dialogRef: MatDialogRef<AddAvailabilityComponent>,
    private service: AvailabilityService,
    @Inject(MAT_DIALOG_DATA) public data: DailyAvailability,
    private translate: TranslateService,
    private companySetting: CompanySettingsService
  ) {}

  ngOnInit(): void {
    if (this.data?.Id) {
      this.formModel = this.data;
      this.formModel.Weekday = this.data.Weekday.toString();
      this.formModel.isWeekendVisible = this.data.isWeekendVisible;
      this.Title = this.translate.instant('EDIT AVAILABILITY');
      this.buttonTitle = this.translate.instant('UPDATED');
    } else if (this.data && !this.data?.isWeekendVisible) {
      this.formModel.Weekday = this.data.Weekday.toString();
      this.formModel.isWeekendVisible = this.data.isWeekendVisible;
      this.formModel.Start = this.getDateHours(this.data.Start);
      this.formModel.End = this.getDateHours(this.data.End);
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
  onSubmit(form: NgForm): void {
    if (form.value) {
      if (this.data && !this.data?.isWeekendVisible) {
        form.value.Weekday = this.data.Weekday.toString();
        form.value.EmployeeId = this.data.EmployeeId;
      }
      this.service.addWeekdayAvailability(form.value as DailyAvailability);
      this.onClose();
    }
  }

  onTimeset(event): void {
    const eventTime = event.split(':');
    this.minEndTime = dayjs()
      .startOf('day')
      .set('h', eventTime[0])
      .set('m', eventTime[1])
      .add(5, 'minutes')
      .format('HH:mm');
  }

  getDateHours(dateString: string | Date): string {
    const date = new Date(dateString);
    return date.toLocaleString([], { hour: '2-digit', minute: '2-digit' });
  }
}
