<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100" id="exampleModalCenterTitle">
        {{ "ADD NEW ABSENCE" | translate }}
      </h5>
    </div>
    <div class="modal-body">
      <form
        class="user"
        id="addAbsence"
        #form="ngForm"
        autocomplete="off"
        (submit)="onSubmit(form)"
      >
        <input
          type="hidden"
          #Id="ngModel"
          name="Id"
          [(ngModel)]="formModel.Id"
        />
        <div *ngIf="authService.isManegeOrTeamLeader" class="row">
          <div
            *ngIf="authService.isManegeOrTeamLeader"
            class="form-group col-md-6 mb-4"
          >
            <label class="required-label">{{
              "EMPLOYEE NAME" | translate
            }}</label>
            <mat-select
              class="form-control"
              #EmployeeId
              name="EmployeeId"
              [(ngModel)]="formModel.EmployeeId"
              placeholder="{{ 'EMPLOYEE NAME' | translate }}"
              disableOptionCentering
              required
            >
              <mat-option>
                <ngx-mat-select-search
                  [formControl]="categoryFilterCtrl"
                ></ngx-mat-select-search>
              </mat-option>
              <ng-container *ngFor="let category of filteredCategory | async">
                <mat-option value="{{ category.Id }}">
                  {{ category.Name }}
                </mat-option>
              </ng-container>
            </mat-select>
          </div>
          <div class="form-group col-md-6 mb-4">
            <label class="required-label">{{
              "ABSENCE TIME RANGE" | translate
            }}</label>
            <mat-date-range-input
              class="form-control"
              [rangePicker]="picker"
              (click)="picker.open()"
            >
              <input
                inputmode="none"
                matStartDate
                name="StarTime"
                [(ngModel)]="formModel.StarTime"
                placeholder="Start date"
                (ngModelChange)="onDateRangeChange()"
                required
              />
              <input
                inputmode="none"
                matEndDate
                name="EndTime"
                [(ngModel)]="formModel.EndTime"
                placeholder="End date"
                (ngModelChange)="onDateRangeChange()"
                required
              />
            </mat-date-range-input>

            <mat-date-range-picker #picker touchUi></mat-date-range-picker>
          </div>
        </div>
        <div *ngIf="!authService.isManegeOrTeamLeader" class="row">
          <div class="form-group col-md-12 mb-4">
            <label class="required-label">{{
              "ABSENCE TIME RANGE" | translate
            }}</label>
            <mat-date-range-input
              class="form-control"
              [rangePicker]="picker"
              (click)="picker.open()"
            >
              <input
                inputmode="none"
                matStartDate
                name="StarTime"
                [(ngModel)]="formModel.StarTime"
                placeholder="Start date"
                (ngModelChange)="onDateRangeChange()"
                required
              />
              <input
                inputmode="none"
                matEndDate
                name="EndTime"
                [(ngModel)]="formModel.EndTime"
                placeholder="End date"
                (ngModelChange)="onDateRangeChange()"
                required
              />
            </mat-date-range-input>

            <mat-date-range-picker #picker touchUi></mat-date-range-picker>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-slide-toggle
              *ngIf="workingDays === 1"
              color="primary"
              [checked]="formModel.AllDay"
              name="AllDay"
              [(ngModel)]="formModel.AllDay"
              >{{
                formModel.AllDay
                  ? ("ALLDAY" | translate)
                  : ("PARTIALDAY" | translate)
              }}
            </mat-slide-toggle>
          </div>

          <div class="col-6" style="text-align: center">
            <h5 *ngIf="workingDays !== null">
              {{ workingDays }}
              {{ workingDays > 1 ? ("DAYS" | translate) : ("Day" | translate) }}
            </h5>
          </div>
        </div>
        <div class="row">
          <div *ngIf="!formModel.AllDay" class="form-group col-md-6 mb-4">
            <mat-label>{{ "START OF ABSENCE" | translate }} </mat-label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  id="end-time-icon"
                  style="height: 40px"
                  ><i class="fa-light fa-clock"></i
                ></span>
              </div>
              <input
                #Start="ngModel"
                name="Start"
                [min]="minTime"
                id="Start"
                class="form-control"
                [format]="24"
                [ngxMatTimepicker]="pickerStart"
                [(ngModel)]="hour.StartTime"
                required
                inputmode="none"
                aria-describedby="end-time-icon"
              />
              <ngx-mat-timepicker
                #pickerStart
                [minutesGap]="5"
                (timeSet)="onTimeset($event)"
              ></ngx-mat-timepicker>
            </div>
          </div>
          <div *ngIf="!formModel.AllDay" class="form-group col-md-6 mb-4">
            <mat-label>{{ "END OF ABSENCE" | translate }} </mat-label>

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  id="end-time-icon"
                  style="height: 40px"
                  ><i class="fa-light fa-clock"></i
                ></span>
              </div>
              <input
                #End="ngModel"
                name="End"
                [min]="minEndTime"
                id="End"
                class="form-control"
                [format]="24"
                [ngxMatTimepicker]="pickerEnd"
                [(ngModel)]="hour.EndTime"
                required
                inputmode="none"
                aria-describedby="end-time-icon"
              />
              <ngx-mat-timepicker
                #pickerEnd
                [minutesGap]="5"
              ></ngx-mat-timepicker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12 mb-4 d-grid">
            <div
              class="btn-group flex-wrap col-md-12"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input
                type="radio"
                class="btn-check"
                value="1"
                [(ngModel)]="formModel.AbsenceType"
                name="AbsenceType"
                id="btnradio2"
                autocomplete="off"
              />
              <label class="btn btn-absence btn-vacation" for="btnradio2">
                <img src="./assets/icons/sunbed-small.png" class="icon" />
                {{ "Vacation" | translate }}
              </label>

              <input
                type="radio"
                class="btn-check"
                value="2"
                [(ngModel)]="formModel.AbsenceType"
                name="AbsenceType"
                id="btnradio3"
                autocomplete="off"
                required
              />
              <label class="btn btn-absence btn-sickness" for="btnradio3">
                <img src="./assets/icons/sick.png" class="icon" />
                {{ "Sickness" | translate }}
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <textarea
              maxlength="500"
              class="form-control form-control-user"
              id="Comment"
              name="Comment"
              placeholder="{{ 'ADD COMMENT OR REASON' | translate }}"
              [(ngModel)]="formModel.Comment"
            ></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-12"></div>
        </div>
      </form>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button
        class="btn btn-outline-secondary"
        (click)="onClose()"
        type="button"
        data-dismiss="modal"
      >
        {{ "Close" | translate }}
      </button>
      <button
        class="btn btn-primary"
        form="addAbsence"
        type="submit"
        [disabled]="
          form.invalid ||
          form.value.AbsenceType == 0 ||
          form.value.EmployeeId == 0
        "
      >
        {{ "Save" | translate }}
      </button>
    </div>
  </div>
</div>
