<div class="row justify-content-center">
  <div class="col-xl-6 col-md-12">
    <div class="card o-hidden border-0 shadow-lg my-5">
      <div class="card-body p-0">
        <div
          class="logo-container d-flex justify-content-center align-items-center"
        >
          <img
            src="../../../../../assets/img/logo-no-name.png"
            alt="Primär-Logo"
            class="logo-primary"
          />
          <img
            src="../../../../../assets/img/Logo.PNG"
            alt="Sekundär-Logo"
            class="logo-secondary"
          />
        </div>
        <!-- Nested Row within Card Body -->
        <div class="row">
          <div class="col-lg-12">
            <div class="p-5">
              <!-- Linear Stepper -->
              <mat-horizontal-stepper #stepper linear>
                <ng-template matStepperIcon="edit">
                  <i class="fas fa-check"></i>
                </ng-template>
                <!-- Schritt 1: Willkommen -->
                <mat-step>
                  <ng-template matStepLabel>
                    {{ "WELCOME" | translate }}
                  </ng-template>

                  <p>
                    {{
                      "THANK YOU FOR REGISTERING WITH OUR PLATFORM" | translate
                    }}
                  </p>
                  <p
                    *ngIf="!(isConfirmed$ | async)"
                    [innerHTML]="
                      'PLEASE CHECK YOUR EMAIL TO COMPLETE THE REGISTRATION PROCESS'
                        | translate
                    "
                  ></p>
                  <p
                    *ngIf="!(isConfirmed$ | async)"
                    [innerHTML]="'IF YOU HAVEN RECEIVED THE EMAIL' | translate"
                  ></p>

                  <p>
                    <b
                      [innerHTML]="
                        'WELCOME TO FIX-PLANNER! IN THE UPCOMING STEPS'
                          | translate
                      "
                    ></b>
                  </p>
                  <br />
                  <p>{{ "READY LET GET STARTED" | translate }}</p>
                  <div class="next-button">
                    <button
                      style="color: white"
                      class="btn btn-primary"
                      matStepperNext
                      (click)="onFirstStepNextButtonClick()"
                    >
                      {{ "LETS GO" | translate }}
                    </button>
                  </div>
                </mat-step>

                <!-- Schritt 2: Struktur der Arbeitsprozesse -->
                <mat-step [completed]="structureStepCompleted">
                  <ng-template matStepLabel>
                    {{ "STRUCTURE_YOUR_WORK_PROCESSES" | translate }}
                  </ng-template>

                  <div class="row" style="padding-top: 10px">
                    <div class="form-group col-md-12 mb-4">
                      <h4 style="color: var(--title-color-blue)">
                        {{ "STRUCTURE_DECISION_TITLE" | translate }}
                      </h4>
                      <p [innerHTML]="structureDecisionText"></p>
                    </div>
                  </div>

                  <form [formGroup]="structureFormGroup">
                    <div class="row">
                      <!-- Departments Card -->
                      <div class="col-md-4">
                        <mat-card
                          class="selectable-card"
                          [ngClass]="{
                            selected:
                              structureFormGroup.get('structureCtrl').value ===
                              WorkStructureEnum.Departments
                          }"
                          (click)="
                            selectStructure(WorkStructureEnum.Departments)
                          "
                        >
                          <mat-card-header>
                            <mat-card-title>{{
                              "DEPARTMENTS" | translate
                            }}</mat-card-title>
                          </mat-card-header>
                          <img
                            mat-card-image
                            src="assets/img/department.png"
                            alt="{{ 'DEPARTMENT_ICON_ALT' | translate }}"
                          />
                          <mat-card-content>
                            <p>{{ "DEPARTMENTS_EXAMPLE" | translate }}</p>
                          </mat-card-content>
                        </mat-card>
                      </div>

                      <!-- Projects Card -->
                      <div class="col-md-4">
                        <mat-card
                          class="selectable-card"
                          [ngClass]="{
                            selected:
                              structureFormGroup.get('structureCtrl').value ===
                              WorkStructureEnum.Projects
                          }"
                          (click)="selectStructure(WorkStructureEnum.Projects)"
                        >
                          <mat-card-header>
                            <mat-card-title>{{
                              "PROJECTS" | translate
                            }}</mat-card-title>
                          </mat-card-header>
                          <img
                            mat-card-image
                            src="assets/img/project-management.png"
                            alt="{{ 'PROJECT_ICON_ALT' | translate }}"
                          />
                          <mat-card-content>
                            <p>{{ "PROJECTS_EXAMPLE" | translate }}</p>
                          </mat-card-content>
                        </mat-card>
                      </div>

                      <!-- Hybrid Card -->
                      <div class="col-md-4">
                        <mat-card
                          class="selectable-card"
                          [ngClass]="{
                            selected:
                              structureFormGroup.get('structureCtrl').value ===
                              WorkStructureEnum.Both
                          }"
                          (click)="selectStructure(WorkStructureEnum.Both)"
                        >
                          <mat-card-header>
                            <mat-card-title>{{
                              "HYBRID" | translate
                            }}</mat-card-title>
                          </mat-card-header>
                          <img
                            mat-card-image
                            src="assets/img/hierarchy-structure.png"
                            alt="{{ 'HYBRID_ICON_ALT' | translate }}"
                          />
                          <mat-card-content>
                            <p>{{ "HYBRID_EXAMPLE" | translate }}</p>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    </div>
                  </form>

                  <div class="next-button" style="padding-top: 30px">
                    <button mat-button matStepperPrevious>
                      {{ "BACK" | translate }}
                    </button>
                    <button
                      style="color: white"
                      class="btn btn-primary"
                      matStepperNext
                      [disabled]="!structureFormGroup.valid"
                      (click)="onSecondFormGroupNextButtonClick()"
                    >
                      {{ "NEXT" | translate }}
                    </button>
                  </div>
                </mat-step>

                <!-- Schritt 3: Firmeninformationen (AddCompanyComponent) -->
                <mat-step [completed]="companyStepCompleted">
                  <ng-template matStepLabel>
                    {{ "COMPANY INFORMATION" | translate }}
                  </ng-template>
                  <div
                    class="alert alert-info"
                    role="alert"
                    [innerHTML]="
                      'PLEASE PROVIDE YOUR COMPANY INFORMATION' | translate
                    "
                  ></div>
                  <!-- Integration von app-add-company -->
                  <app-add-company
                    #addCompanyComponent
                    (onSave)="onCompanySubmit($event)"
                    [showButtons]="false"
                    [showOnboarding]="true"
                  ></app-add-company>
                  <div class="next-button">
                    <button mat-button matStepperPrevious>
                      {{ "BACK" | translate }}
                    </button>
                    <button
                      style="color: white"
                      class="btn btn-primary"
                      (click)="addCompanyComponent.save()"
                    >
                      {{ "Save changes" | translate }}
                    </button>
                  </div>
                </mat-step>

                <!-- Schritt 4: Qualification -->
                <mat-step [completed]="secondStepCompleted">
                  <ng-template matStepLabel>
                    <span>{{ "QUALIFICATION" | translate }}</span>
                  </ng-template>
                  <div
                    class="alert alert-info"
                    role="alert"
                    [innerHTML]="
                      'EDIT OR ADD QUALIFICATIONS FOR EACH DEPARTMENT'
                        | translate
                    "
                  ></div>
                  <!-- Integration von app-add-skill -->
                  <app-add-skill
                    #addSkillComponent
                    (onSave)="onSkillSubmit($event)"
                    [showButtons]="false"
                    [showOnboarding]="true"
                  ></app-add-skill>

                  <div class="next-button">
                    <button mat-button matStepperPrevious>
                      {{ "BACK" | translate }}
                    </button>
                    <button
                      style="color: white"
                      class="btn btn-primary"
                      (click)="addSkillComponent.save()"
                      [disabled]="!addSkillComponent.isValid"
                    >
                      {{ "Save changes" | translate }}
                    </button>
                  </div>
                </mat-step>

                <!-- Schritt 3: Department -->
                <mat-step [completed]="thirdStepCompleted">
                  <ng-template matStepLabel>
                    <span>{{ "Department" | translate }}</span>
                  </ng-template>
                  <div
                    class="alert alert-info"
                    role="alert"
                    *ngIf="formModelDepartement.Type === 1"
                    [innerHTML]="
                      'EXPLANATION FOR THE CATEGORY DEPARTMENT' | translate
                    "
                  ></div>
                  <div
                    class="alert alert-info"
                    role="alert"
                    *ngIf="formModelDepartement.Type === 2"
                    [innerHTML]="
                      'EXPLANATION FOR THE CATEGORY PROJECT' | translate
                    "
                  ></div>
                  <app-add-departement
                    #addDepartementComponent
                    [formModel]="formModelDepartement"
                    (onSave)="onDepartementSubmit($event)"
                    [showButtons]="false"
                    [showOnboarding]="true"
                  ></app-add-departement>

                  <div class="next-button">
                    <button mat-button matStepperPrevious>
                      {{ "BACK" | translate }}
                    </button>
                    <button
                      style="color: white"
                      class="btn btn-primary"
                      matStepperNext
                      (click)="addDepartementComponent.save()"
                      [disabled]="!addDepartementComponent.isValid"
                    >
                      {{ "Save changes" | translate }}
                    </button>
                  </div>
                </mat-step>

                <!-- Schritt 5: Employer -->
                <mat-step [completed]="fourthStepCompleted">
                  <ng-template matStepLabel>
                    <span>{{ "EMPLOYER" | translate }}</span>
                  </ng-template>
                  <div
                    class="alert alert-info"
                    role="alert"
                    [innerHTML]="
                      'ADD AS MANY EMPLOYEES AS NEEDED AND ASSIGN THEM TO DEPARTMENTS AND QUALIFICATIONS'
                        | translate
                    "
                  ></div>
                  <app-add-employee
                    #addEmployeeComponent
                    (onSave)="onEmployeeSubmit($event)"
                    [showButtons]="false"
                    [showOnboarding]="true"
                  ></app-add-employee>

                  <div class="next-button">
                    <button mat-button matStepperPrevious>
                      {{ "BACK" | translate }}
                    </button>
                    <button
                      style="color: white"
                      class="btn btn-primary"
                      matStepperNext
                      (click)="addEmployeeComponent.save()"
                      [disabled]="!addEmployeeComponent.isValid"
                    >
                      {{ "Save changes" | translate }}
                    </button>
                  </div>
                </mat-step>

                <!-- Schritt 6: Done -->
                <mat-step [completed]="completed" [state]="state">
                  <ng-template matStepLabel>Done</ng-template>
                  <div
                    [innerHTML]="
                      'CONGRATULATIONS YOU HAVE SUCCESSFULLY ADDED DEPARTMENTS'
                        | translate
                    "
                  ></div>
                  <div class="next-button">
                    <button mat-button matStepperPrevious>
                      {{ "BACK" | translate }}
                    </button>
                    <button
                      style="color: white"
                      class="btn btn-primary"
                      (click)="done()"
                    >
                      {{ "FINISH" | translate }}
                    </button>
                  </div>
                </mat-step>
              </mat-horizontal-stepper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
