<div class="col-12" style="padding-top: 25px">
  <h3 class="page-title">
    {{ "Absence" | translate }}
  </h3>
</div>
<div class="row">
  <div class="col-lg-4 mb-4" *ngIf="absenceService.absenceCount">
    <app-absence-requests></app-absence-requests>
  </div>
  <div class="col-lg-8 mb-4" *ngIf="absenceService.absenceCount">
    <div class="card shadow mb-4">
      <div class="card-body" style="padding-left: 0px; padding-right: 0px">
        <app-absence-calendar></app-absence-calendar>
      </div>
    </div>
  </div>
  <div class="col-lg-12 mb-4" *ngIf="!absenceService.absenceCount">
    <div class="card shadow mb-4">
      <div class="card-body" style="padding-left: 0px; padding-right: 0px">
        <app-absence-calendar></app-absence-calendar>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-4 mb-4">
    <div class="card shadow mb-4">
      <div class="card-body" style="padding-left: 0px; padding-right: 0px">
        <app-absence-summary-chart></app-absence-summary-chart>
      </div>
    </div>
  </div>
  <div class="col-lg-8 mb-4">
    <div class="card shadow mb-4">
      <div class="card-body">
        <app-absence-all-employee-chart></app-absence-all-employee-chart>
      </div>
    </div>
  </div>
</div>
