<div
  class="divbtn"
  style="margin-left: 0px; margin-right: 0px; border-radius: 20px; width: 100%"
>
  <div class="col-12 col-sm-12 text-center">
    <span style="font-size: small; font-weight: bolder">
      {{ timeChart.ChartTitle | translate }}</span
    >
  </div>
  <!-- Force next columns to break to new line at md breakpoint and up -->
  <div class="w-100 d-none d-md-block"></div>
  <div
    class="col-12 col-sm-12"
    style="
      cursor: pointer !important;
      padding-right: 0px;
      padding-left: 0px;
      cursor: pointer;
    "
  >
    <div echarts [options]="chartOption" style="height: 100px"></div>
  </div>
  <div class="w-100 d-none d-md-block"></div>
</div>
