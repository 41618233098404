import { animate, keyframes, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { FullCalendarComponent } from '@fullcalendar/angular';
import {
  CalendarOptions,
  DateSelectArg,
  EventApi,
  EventClickArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { retry, Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/core/component/confirmation-dialog/confirmation-dialog.component';
import { AddAbsenceComponent } from 'src/app/modules/absence/component/add-absence/add-absence.component';
import { AbsenceService } from 'src/app/modules/absence/services/absence.service';
import tippy, {
  followCursor,
  hideAll,
  inlinePositioning,
  roundArrow,
} from 'tippy.js';
import * as kf from './keyframes';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';

@Component({
  selector: 'app-employee-absence-calendar',
  templateUrl: './employee-absence-calendar.component.html',
  styleUrls: ['./employee-absence-calendar.component.css'],
  animations: [
    trigger('cardAnimator', [
      transition('* => wobble', animate(1000, keyframes(kf.wobble))),
      transition('* => swing', animate(1000, keyframes(kf.swing))),
      transition('* => jello', animate(1000, keyframes(kf.jello))),
      transition(
        '* => zoomOutRight',
        animate(1000, keyframes(kf.zoomOutRight))
      ),
      transition('* => slideOutLeft', animate(300, keyframes(kf.slideOutLeft))),
      transition(
        '* => slideOutRight',
        animate(300, keyframes(kf.slideOutRight))
      ),
      transition(
        '* => rotateOutUpRight',
        animate(1000, keyframes(kf.rotateOutUpRight))
      ),
      transition('* => flipOutY', animate(1000, keyframes(kf.flipOutY))),
    ]),
  ],
})
export class EmployeeAbsenceCalendarComponent implements OnInit, AfterViewInit {
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
    locale: this.translate.currentLang,
    firstDay: 1,
    height: 'auto',
    initialView: 'dayGridMonth',
    headerToolbar: {
      left: '',
      center: 'title',
      right: 'addAbsenceButtonEmployee',
    },
    customButtons: {
      addAbsenceButtonEmployee: {
        text: this.translate.instant('ADD'),
        click: () => this.addAbsence(),
      },
      prev: {
        click: () => this.selectPrevButton(),
      },
      next: {
        click: () => this.selectNextButton(),
      },
    },
    displayEventTime: true,
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    eventChange: this.handleEventChange.bind(this),
    eventAdd: this.handleEventAdd.bind(this),
    eventReceive: this.handleDropInfo.bind(this),
    eventDidMount: this.handleEventDidMount.bind(this),
    handleWindowResize: true,
    eventContent: this.handleEventContent.bind(this),
    selectConstraint: {
      start: dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
      end: dayjs().add(1, 'years').format('YYYY-MM-DD'),
    },
    dayCellClassNames: this.handleDayCellClassNames.bind(this),
  };

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  calendarVisible = true;
  currentEvents: EventApi[] = [];
  calendarApi: any;
  animationState: string;
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  constructor(
    private service: AbsenceService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private translate: TranslateService,
    private el: ElementRef,
    private companySetting: CompanySettingsService
  ) {
    Window['EmployeeAbsenceCalendarComponent'] = this;
  }
  private subscriptionEmployeeEventList: Subscription;
  HTMLElement: HTMLImageElement;
  ngOnInit(): void {
    this.subscriptionEmployeeEventList =
      this.service.employeeAbsenceList.subscribe((list) => {
        if (this.calendarComponent) {
          const calendar = this.calendarComponent.getApi();
          if (
            calendar &&
            JSON.stringify(this.calendarOptions.events) !== JSON.stringify(list)
          ) {
            calendar.removeAllEvents();
          }
        }

        this.calendarOptions.events = list;

        if (this.HTMLElement?.textContent) {
          this.HTMLElement.textContent =
            ' ' + this.translate.instant('Absence') + ' ';
        }
      });
  }

  ngAfterViewInit(): void {
    this.calendarApi = this.calendarComponent.getApi();
    const calendar = this.calendarComponent.getApi();
    this.service.getAbsence(calendar.getCurrentData().viewApi.currentStart);
    this.HTMLElement = this.el.nativeElement.querySelector('#fc-dom-2');
  }
  handleCalendarToggle(): void {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle(): void {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  addAbsence(startTime = null, endTime = null): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = { startTime, endTime };
    dialogConfig.panelClass = 'custom-dialog-container';
    this.dialog.open(AddAbsenceComponent, dialogConfig);
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    this.addAbsence(selectInfo.startStr, selectInfo.endStr);
  }

  handleEventClick(clickInfo: EventClickArg): void {}

  handleEvents(events: EventApi[]): void {
    this.currentEvents = events;
    this.cdr.detectChanges();
  }

  handleEventChange(events: any): void {}

  handleEventAdd(events: EventApi[]): void {
    var test = events;
    this.cdr.detectChanges();
  }

  handleDropInfo(events: any): void {}
  onSwipeLeft(evt): void {
    if (!this.animationState) {
      this.animationState = 'slideOutLeft';
    }
    this.selectNextButton();
  }
  onSwipeRight(evt): void {
    if (!this.animationState) {
      this.animationState = 'slideOutRight';
    }
    this.selectPrevButton();
  }
  selectPrevButton(): void {
    const calendar = this.calendarComponent.getApi();
    calendar.prev();
    this.service.getAbsence(calendar.getCurrentData().viewApi.currentStart);
  }

  selectNextButton(): void {
    const calendar = this.calendarComponent.getApi();
    calendar.next();
    this.service.getAbsence(calendar.getCurrentData().viewApi.currentStart);
  }
  resetAnimationState(): void {
    this.animationState = '';
  }
  deleteAbsence(absenceId): void {
    // after click on tippy close the tippy and open the dialog
    setTimeout(() => {
      hideAll();
    }, 100);
    const calendar = this.calendarComponent.getApi();
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'custom-dialog-container',
    });

    const absenceType =
      calendar.getEventById(absenceId).extendedProps.absenceType;
    if (absenceType === 2) {
      this.dialogRef.componentInstance.confirmMessage = this.translate.instant(
        'Do you want to delete this sick day'
      );
    } else if (absenceType === 1) {
      this.dialogRef.componentInstance.confirmMessage = this.translate.instant(
        'Do you want to delete this vacation day'
      );
    }

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.service.deleteAbsence(
          absenceId,
          calendar.getCurrentData().viewApi.currentStart,
          calendar.getCurrentData().viewApi.currentEnd
        );
      }
      this.dialogRef = null;
    });
  }

  handleEventContent(event): any {
    let content = '';

    if (event.event.extendedProps.eventType === 5) {
      content +=
        '<strong style="color: #000000" >' +
        event.event.title +
        '</strong><br>';
    }
    // Name des Mitarbeiters

    // Art des Eintrags
    let absenceType = this.translate.instant('Vacation');
    if (event.event.extendedProps.absenceType === 2) {
      absenceType = this.translate.instant('Sick');
    }

    let requestStatusText = '';
    if (event.event.extendedProps.requestStatus === 4) {
      requestStatusText = this.translate.instant('REJECTED');
    }

    if (event.event.extendedProps.eventType === 5) {
      absenceType = this.translate.instant('HOLIDAY');
    }

    content +=
      '<em>' + absenceType + '</em> <b>' + requestStatusText + ' </b><br>';

    // Zeitraum
    if (event.event.allDay) {
      // Ganztägiger Eintrag
      // Ganztägiger Eintrag
      const startDate = dayjs.utc(event.event.start).format('DD.MM');
      const endDate = dayjs
        .utc(event.event.end)
        .subtract(1, 'days')
        .format('DD.MM');

      if (startDate !== endDate) {
        const options: any = {
          month: '2-digit',
          day: '2-digit',
        };
        //const startDate = moment.utc(event.event.start).format('DD.MM');
        const starTimeString = new Date(event.event.start).toLocaleDateString(
          navigator.language,
          options
        );
        const endTimeString = dayjs(event.event.end)
          .subtract(1, 'minute')
          .toDate()
          .toLocaleDateString(navigator.language, options);
        content +=
          this.translate.instant('FROM') +
          ' ' +
          starTimeString +
          ' ' +
          this.translate.instant('TO') +
          ' ' +
          endTimeString;
      }
    } else {
      // Eintrag mit Uhrzeit
      const startTime = dayjs(event.event.start).format('HH:mm');
      const endTime = dayjs(event.event.end).format('HH:mm');
      content += startTime + ' - ' + endTime;
      content = '<div> ' + content + ' </div>';
    }

    return {
      html: content,
    };
  }

  handleEventDidMount(info): any {
    if (info.event.start < dayjs().startOf('day')) {
      return;
    }

    tippy(info.el, {
      content:
        '<div><span onclick="Window.EmployeeAbsenceCalendarComponent.deleteAbsence(\'' +
        info.event.id +
        '\')" ><i class="far fa-trash-alt fa-2x fa-clickable">' +
        '</i></span> </div>',
      allowHTML: true,
      plugins: [inlinePositioning, followCursor],
      theme: 'light',
      trigger: 'click',
      followCursor: 'initial',
      arrow: roundArrow,
      popperOptions: { strategy: 'fixed' },
      interactive: true,
      interactiveBorder: 30,
      interactiveDebounce: 0,
      appendTo: document.body,
      animation: 'scale-extreme',
      onMount(instance): void {
        const box = instance.popper.firstElementChild;
        requestAnimationFrame(() => {
          box.classList.add('animated');
          box.classList.add('wobble');
        });
      },
    });
  }

  handleDayCellClassNames(arg: any): any {
    const day = arg.date.getDay(); // 0 (Sonntag) bis 6 (Samstag)
    const weekendDays =
      this.companySetting.CompanySettings.CalendarOptions.WeekendDays;

    // Wenn der Tag ein Wochenende ist, CSS-Klasse hinzufügen
    if (weekendDays.includes(day)) {
      return ['weekend-day'];
    }
  }
}
