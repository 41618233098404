<div class="modal-dialog modal-lg custom-modal" role="document">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100">
        {{ "Edit Company Information" | translate }}
      </h5>
      <app-toggle-button
        [isActive]="isOnboardingActive"
        (toggled)="toggleOnboarding()"
        *ngIf="!showOnboarding"
      ></app-toggle-button>
    </div>
    <div class="modal-body">
      <form #companyForm="ngForm" (ngSubmit)="onSubmit(companyForm)">
        <!-- Hauptdaten -->
        <div class="row">
          <div class="form-group col-md-6 mb-4">
            <label for="Name" class="required-label">{{
              "Company Name" | translate
            }}</label>
            <input
              type="text"
              class="form-control"
              id="Name"
              name="Name"
              [(ngModel)]="companyDetails.Name"
              placeholder="{{ 'Enter Company Name' | translate }}"
              required
              onboardingAnchor="name-add-company"
            />
          </div>
          <div class="form-group col-md-6 mb-4">
            <label for="CompanyTypeId">{{ "Company Type" | translate }}</label>
            <mat-select
              class="form-control"
              id="CompanyTypeId"
              name="CompanyTypeId"
              [(ngModel)]="companyDetails.CompanyTypeId"
              placeholder="{{ 'Select Company Type' | translate }}"
              required
              onboardingAnchor="company-type-add-company"
            >
              <mat-option *ngFor="let type of companyTypes" [value]="type.Id">
                {{ type.Name | translate }}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6 mb-4">
            <label for="PhoneNumber">{{ "Phone Number" | translate }}</label>
            <input
              type="text"
              class="form-control"
              id="PhoneNumber"
              name="PhoneNumber"
              [(ngModel)]="companyDetails.PhoneNumber"
              placeholder="{{ 'Enter Phone Number' | translate }}"
              onboardingAnchor="phone-number-add-company"
            />
          </div>
          <div class="form-group col-md-6 mb-4">
            <label for="TimeZone" class="required-label">{{
              "TIME ZONE" | translate
            }}</label>
            <mat-select
              class="form-control"
              id="TimeZone"
              name="TimeZone"
              [(ngModel)]="companySettings.TimeZone"
              placeholder="{{ 'Select Time Zone' | translate }}"
              required
              onboardingAnchor="time-zone-add-company"
            >
              <mat-option *ngFor="let tz of timeZones" [value]="tz.key">
                {{ tz.value }}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6 mb-4">
            <label for="IsoCountryCode" class="required-label">{{
              "COUNTRY" | translate
            }}</label>
            <mat-select
              class="form-control"
              id="IsoCountryCode"
              name="IsoCountryCode"
              [(ngModel)]="companySettings.IsoCountryCode"
              (selectionChange)="onCountryChange()"
              placeholder="{{ 'Select Country' | translate }}"
              required
              onboardingAnchor="country-add-company"
            >
              <mat-option
                *ngFor="let country of countries"
                [value]="country.key"
              >
                {{ country.value }}
              </mat-option>
            </mat-select>
          </div>
          <div class="form-group col-md-6 mb-4">
            <label for="IsoRegionCode" class="required-label">{{
              "REGION/STATE" | translate
            }}</label>
            <mat-select
              class="form-control"
              id="IsoRegionCode"
              name="IsoRegionCode"
              [(ngModel)]="companySettings.IsoRegionCode"
              placeholder="{{ 'Select Region/State' | translate }}"
              required
              [disabled]="!regions.length"
              onboardingAnchor="region-add-company"
            >
              <mat-option *ngFor="let region of regions" [value]="region.key">
                {{ region.value }}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <!-- Akkordeon für Adresse und Steuernummer -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            {{ "Address and Tax Information" | translate }}
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <!-- Adressfelder -->
            <div class="row">
              <div class="form-group col-md-6 mb-4">
                <label for="Street">{{ "Street" | translate }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="Street"
                  name="Address.Street"
                  [(ngModel)]="companyDetails.Address.Street"
                />
              </div>
              <div class="form-group col-md-6 mb-4">
                <label for="HouseNumber">{{
                  "House Number" | translate
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="HouseNumber"
                  name="Address.HouseNumber"
                  [(ngModel)]="companyDetails.Address.HouseNumber"
                />
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-6 mb-4">
                <label for="PostalCode">{{ "Postal Code" | translate }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="PostalCode"
                  name="Address.PostalCode"
                  [(ngModel)]="companyDetails.Address.PostalCode"
                />
              </div>
              <div class="form-group col-md-6 mb-4">
                <label for="City">{{ "City" | translate }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="City"
                  name="Address.City"
                  [(ngModel)]="companyDetails.Address.City"
                />
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-6 mb-4">
                <label for="Country">{{ "Country" | translate }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="Country"
                  name="Address.Country"
                  [(ngModel)]="companyDetails.Address.Country"
                />
              </div>
            </div>

            <!-- Feld für Steuernummer -->
            <div class="row">
              <div class="form-group col-md-6 mb-4">
                <label for="TaxId">{{ "Tax ID" | translate }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="TaxId"
                  name="TaxId"
                  [(ngModel)]="companyDetails.TaxId"
                />
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>

        <!-- Modal Footer -->
        <div
          class="modal-footer d-flex justify-content-between"
          *ngIf="showButtons"
        >
          <button
            class="btn btn-outline-secondary"
            type="button"
            (click)="onCancel()"
          >
            {{ "Close" | translate }}
          </button>
          <button
            class="btn btn-primary active"
            type="submit"
            [disabled]="!companyForm.valid"
          >
            {{ "Save changes" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
