import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-onboarding-tooltip',
  template: `
    <div
      class="onboarding-tooltip"
      [ngClass]="placement"
      [@fadeInOut]
      (@fadeInOut.done)="animationDone()"
      [ngStyle]="mergedStyles"
    >
      <button class="close-button" (click)="close()">×</button>
      <div class="tooltip-content">
        <h3 class="onboarding-tooltip-title">{{ title }}</h3>
        <div class="onboarding-tooltip-text" [innerHTML]="content"></div>
      </div>
    </div>
  `,
  styles: [
    `
      .onboarding-tooltip {
        position: relative;
        background-color: #fff;
        border: 2px solid transparent;
        border-image: linear-gradient(to right, #a1c4fd, #c2e9fb) 1;
        padding: 15px;
        max-width: 250px;
        box-shadow: 0 8px 20px rgba(63, 81, 181, 0.2);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        transform-style: preserve-3d;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
      }

      .onboarding-tooltip:hover {
        box-shadow: 0 12px 24px rgba(63, 81, 181, 0.3);
      }

      .onboarding-tooltip-title {
        font-weight: bold;
        margin-bottom: 8px;
        font-size: 18px;
        color: #3f51b5;
      }

      .onboarding-tooltip-text {
        color: #555;
        font-size: 14px;
        line-height: 1.5;
      }

      .close-button {
        position: absolute;
        top: 8px;
        right: 8px;
        background: transparent;
        border: none;
        font-size: 20px;
        cursor: pointer;
        color: #999;
        transition: color 0.2s ease;
      }

      .close-button:hover {
        color: #3f51b5;
      }

      /* Pfeil-Styling */
      .onboarding-tooltip::after,
      .onboarding-tooltip::before {
        content: '';
        position: absolute;
        border-style: solid;
      }

      /* Pfeil nach oben */
      .top::after,
      .top::before {
        left: 50%;
        transform: translateX(-50%);
      }

      .top::after {
        bottom: -10px;
        border-width: 10px 10px 0 10px;
        border-color: #fff transparent transparent transparent;
      }

      .top::before {
        bottom: -12px;
        border-width: 12px 12px 0 12px;
        border-color: #a1c4fd transparent transparent transparent;
      }

      /* Pfeil nach unten */
      .bottom::after,
      .bottom::before {
        left: 50%;
        transform: translateX(-50%);
      }

      .bottom::after {
        top: -10px;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #fff transparent;
      }

      .bottom::before {
        top: -12px;
        border-width: 0 12px 12px 12px;
        border-color: transparent transparent #a1c4fd transparent;
      }

      /* Pfeil nach links */
      .left::after,
      .left::before {
        top: 50%;
        transform: translateY(-50%);
      }

      .left::after {
        right: -10px;
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent #fff;
      }

      .left::before {
        right: -12px;
        border-width: 12px 0 12px 12px;
        border-color: transparent transparent transparent #a1c4fd;
      }

      /* Pfeil nach rechts */
      .right::after,
      .right::before {
        top: 50%;
        transform: translateY(-50%);
      }

      .right::after {
        left: -10px;
        border-width: 10px 10px 10px 0;
        border-color: transparent #fff transparent transparent;
      }

      .right::before {
        left: -12px;
        border-width: 12px 12px 12px 0;
        border-color: transparent #a1c4fd transparent transparent;
      }
    `,
  ],

  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.95)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        animate(
          '150ms ease-in',
          style({ opacity: 0, transform: 'scale(0.95)' })
        ),
      ]),
    ]),
  ],
})
export class OnboardingTooltipComponent {
  @Input() title: string;
  @Input() content: string;
  @Input() placement: 'left' | 'right' | 'top' | 'bottom';
  @Input() width: string; // Neue Property für Breite
  @Input() height: string; // Neue Property für Höhe
  @Input() customStyles: { [key: string]: any } = {}; // Weitere Styles
  @Output() closeTooltip = new EventEmitter<void>();

  get mergedStyles(): { [key: string]: any } {
    return {
      width: this.width,
      height: this.height,
      ...this.customStyles,
    };
  }

  close() {
    this.closeTooltip.emit();
  }

  animationDone() {
    // Optional: Logik nach Abschluss der Animation
  }
}
