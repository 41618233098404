<div class="row">
  <div class="col-lg-3 mb-4">
    <form [formGroup]="filterForm">
      <div class="d-flex flex-nowrap">
        <div class="form-group flex-fill mb-3">
          <mat-label>{{ "Employee" | translate }}</mat-label>
          <mat-select
            formControlName="employee"
            class="form-control"
            (selectionChange)="onInputChange('employee')"
          >
            <mat-option>
              <ngx-mat-select-search
                [formControl]="employeeFilterCtrl"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option [value]="-1">{{
              "ALL EMPLOYEES" | translate
            }}</mat-option>
            <ng-container *ngFor="let employee of filteredEmployees | async">
              <mat-option [value]="employee.Id">{{ employee.Name }}</mat-option>
            </ng-container>
          </mat-select>
        </div>
      </div>
      <div
        *ngIf="filterForm.hasError('dates')"
        class="alert alert-danger col-12"
        role="alert"
      >
        {{ "Start date should be less than end date" | translate }}
      </div>
    </form>
  </div>
  <div class="col-lg-9 mb-4 chart-container">
    <app-absence-holiday-chart
      style="justify-content: center; align-items: center"
      [employeeId]="filterForm.value.employee"
    ></app-absence-holiday-chart>
  </div>
</div>
