import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EChartsOption, ECharts } from 'echarts';
import { Subscription } from 'rxjs';
import { HolidaysChart } from '../../../admin/model/holidaysChart.model';
import { AbsenceService } from '../../services/absence.service';
import * as echarts from 'echarts'; // Import für Farbverläufe

@Component({
  selector: 'app-absence-holiday-chart',
  templateUrl: './absence-holiday-chart.component.html',
  styleUrls: ['./absence-holiday-chart.component.css'],
})
export class AbsenceHolidayChartComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() employeeId = 0;
  holidaysChart: HolidaysChart;
  private chartInstance: ECharts;

  constructor(
    private service: AbsenceService,
    private translate: TranslateService
  ) {}
  private subscriptionHolidaysForEmployee: Subscription;
  chartOption: EChartsOption;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.employeeId) {
      this.updateChart();
      console.log('changes.employeeId');
    }
  }
  ngOnInit(): void {
    this.updateChart();
  }

  updateChart(): void {
    this.subscriptionHolidaysForEmployee = this.service
      .getHolidaysForEmployeeChartAsync(this.employeeId)
      .subscribe((holidaysChart: HolidaysChart) => {
        this.holidaysChart = holidaysChart;
        this.drewChart(holidaysChart);
      });
  }

  drewChart(holidaysChart: HolidaysChart): void {
    const data = [];

    if (!holidaysChart) {
      return;
    }

    // Farbenzuordnung für jeden Datentyp (inkl. textColor)
    const colorMapping = {
      SickDays: {
        color: '#B71C1C',
        gradientColor: '#EF5350',
        textColor: '#FFFFFF', // Weiß
      },
      PendingSickDays: {
        color: '#E57373',
        gradientColor: '#FFCDD2',
        textColor: '#000000', // Schwarz
      },
      Planned: {
        color: '#FFA726',
        gradientColor: '#FFE0B2',
        textColor: '#000000', // Schwarz
      },
      Approved: {
        color: '#E65100',
        gradientColor: '#FF9800',
        textColor: '#FFFFFF', // Weiß
      },
      RemainingLeaveCurrentYear: {
        color: '#003f5c',
        gradientColor: '#066f99df',
        textColor: '#FFFFFF', // Weiß
      },
    };

    // Daten für Krankentage (genehmigt)
    if (holidaysChart.SickDays !== 0) {
      const sickDaysLabel =
        this.translate.instant('ILLNESS DAYS') +
        ' (' +
        holidaysChart.SickDays +
        ' ' +
        this.translate.instant('DAYS') +
        ')';
      data.push({
        value: holidaysChart.SickDays,
        name: sickDaysLabel,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
            { offset: 0, color: colorMapping.SickDays.color },
            { offset: 1, color: colorMapping.SickDays.gradientColor },
          ]),
        },
        label: {
          color: colorMapping.SickDays.textColor,
        },
      });
    }

    // Daten für beantragte Krankheitstage
    if (holidaysChart.PendingSickDays !== 0) {
      const pendingSickDaysLabel =
        this.translate.instant('PENDING ILLNESS DAYS') +
        ' (' +
        holidaysChart.PendingSickDays +
        ' ' +
        this.translate.instant('DAYS') +
        ')';
      data.push({
        value: holidaysChart.PendingSickDays,
        name: pendingSickDaysLabel,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
            { offset: 0, color: colorMapping.PendingSickDays.color },
            { offset: 1, color: colorMapping.PendingSickDays.gradientColor },
          ]),
        },
        label: {
          color: colorMapping.PendingSickDays.textColor,
        },
      });
    }

    // Daten für geplante Urlaubstage
    if (holidaysChart.Planned != null && holidaysChart.Planned !== 0) {
      const plannedLabel =
        this.translate.instant('PLANNED VACATION') +
        ' (' +
        holidaysChart.Planned +
        ' ' +
        this.translate.instant('DAYS') +
        ')';
      data.push({
        value: holidaysChart.Planned,
        name: plannedLabel,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
            { offset: 0, color: colorMapping.Planned.color },
            { offset: 1, color: colorMapping.Planned.gradientColor },
          ]),
        },
        label: {
          color: colorMapping.Planned.textColor,
        },
      });
    }

    // Daten für genehmigte Urlaubstage
    if (holidaysChart.Approved && holidaysChart.Approved !== 0) {
      const approvedLabel =
        this.translate.instant('APPROVED VACATION') +
        ' (' +
        holidaysChart.Approved +
        ' ' +
        this.translate.instant('DAYS') +
        ')';
      data.push({
        value: holidaysChart.Approved,
        name: approvedLabel,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
            { offset: 0, color: colorMapping.Approved.color },
            { offset: 1, color: colorMapping.Approved.gradientColor },
          ]),
        },
        label: {
          color: colorMapping.Approved.textColor,
        },
      });
    }

    // Daten für verbleibende Urlaubstage
    if (
      holidaysChart.RemainingLeaveCurrentYear != null &&
      holidaysChart.RemainingLeaveCurrentYear !== 0
    ) {
      const remainingLabel =
        this.translate.instant('REMAINING VACATION DAYS') +
        ' (' +
        holidaysChart.RemainingLeaveCurrentYear +
        ' ' +
        this.translate.instant('DAYS') +
        ')';
      data.push({
        value: holidaysChart.RemainingLeaveCurrentYear,
        name: remainingLabel,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
            { offset: 0, color: colorMapping.RemainingLeaveCurrentYear.color },
            {
              offset: 1,
              color: colorMapping.RemainingLeaveCurrentYear.gradientColor,
            },
          ]),
        },
        label: {
          color: colorMapping.RemainingLeaveCurrentYear.textColor,
        },
      });
    }

    this.chartOption = {
      animation: true,
      animationType: 'scale',
      animationEasing: 'bounceOut',
      title: {
        text: this.translate.instant('ILLNESS AND VACATION DAYS'),
        subtext: '',
        left: 'center',
        textStyle: {
          fontSize: 18,
          fontWeight: 'bold',
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ' + this.translate.instant('DAYS') + ' ({d}%)',
        position: 'top',
        borderWidth: 1,
      },
      legend: {
        show: true,
        orient: 'horizontal',
        bottom: 'bottom',
        left: 'left',
        padding: [10, 0, 0, 0],
        textStyle: {
          fontSize: 15,
        },
        data: data.map((item) => item.name),
      },
      series: [
        {
          name: 'Urlaub',
          type: 'pie',
          radius: '70%',
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 20,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          itemStyle: {
            borderRadius: 9,
            borderColor: '#fff',
            borderWidth: 3,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
          label: {
            show: true,
            formatter: '{c} ' + this.translate.instant('DAYS'),
            fontSize: 13,
            position: 'inside',
            lineHeight: 60,
            fontWeight: 'bold',
            // Entfernen der globalen Farbeinstellung
          },
          labelLine: {
            show: true,
          },
        },
      ],
      grid: {
        containLabel: true,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    };

    // Initialisierung oder Aktualisierung der Chart-Instanz
    setTimeout(() => {
      if (this.chartInstance) {
        this.chartInstance.setOption(this.chartOption);
      }
    }, 100);
  }

  ngOnDestroy(): void {
    this.subscriptionHolidaysForEmployee.unsubscribe();
  }
}
