import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { Departement } from 'src/app/modules/departement/model/departement';
import { DepartementService } from 'src/app/modules/departement/services/departement.service';
import { Skill } from 'src/app/modules/skill/model/departement';
import { SkillService } from 'src/app/modules/skill/services/skill.service';
import { v4 as uuidv4 } from 'uuid';
import { EmployeeListByDateDto } from '../../model/department-dto.model';
import { ScheduleRequirementEvent } from '../../model/schedule-requirement-event';
import { ScheduleRequirementService } from '../../services/schedule-requirement.service';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';

@Component({
  selector: 'app-add-schedule-requirement',
  templateUrl: './add-schedule-requirement.component.html',
  styleUrls: ['./add-schedule-requirement.component.css'],
})
export class AddScheduleRequirementComponent implements OnInit, OnDestroy {
  public filteredCategory: ReplaySubject<EmployeeListByDateDto[]> =
    new ReplaySubject<EmployeeListByDateDto[]>(1);
  public categoryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  employeeCategory: EmployeeListByDateDto[];
  protected _onDestroy = new Subject<void>();
  @ViewChild('EmployeeId', { static: true }) categorySelect: MatSelect;
  IncomeTypeId = '';

  minEndTime =
    this.companySetting.CompanySettings.CalendarOptions.BusinessHoursStartTime;

  minTime =
    this.companySetting.CompanySettings.CalendarOptions.BusinessHoursStartTime;

  private subscriptionDepartmentList: Subscription;
  private subscriptionSkillList: Subscription;
  departmentList = [];
  skillList = [];
  selected: string[];
  departmentValue = new UntypedFormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddScheduleRequirementComponent>,
    private service: ScheduleRequirementService,
    public departementService: DepartementService,
    private translate: TranslateService,
    private skillService: SkillService,
    private companySetting: CompanySettingsService
  ) {}

  formModel: ScheduleRequirementEvent = {
    EventId: null,
    StarTime: null,
    EndTime: null,
    SkillId: 0,
    NumberOfStaff: 1,
  };
  heder = null;
  selectedValue: {};
  hour = {
    StarTime: '00:00',
    EndTime: '00:00',
  };
  ngOnInit(): void {
    if (this.data && this.data.workShift) {
      this.formModel = {
        StarTime: this.data.workShift.StarTime,
        EndTime: this.data.workShift.EndTime,
        EventId: null,
        SkillId: this.data.workShift.SkillId,
        NumberOfStaff: this.data.workShift.NumberOfStaff
          ? this.data.workShift.NumberOfStaff
          : 1,
      };

      this.heder = this.data.workShift.addWorkShift
        ? this.translate.instant('ADD REQUIREMENT')
        : this.translate.instant('EDIT REQUIREMENT');
      this.hour.StarTime = this.getDateHours(this.data.workShift.StarTime);
      this.hour.EndTime = this.getDateHours(this.data.workShift.EndTime);
    } else {
      if (this.skillList) {
        this.selectedValue = this.skillList[0];
        this.formModel.SkillId = this.skillList[0]?.Id;
      }
    }

    this.subscriptionDepartmentList =
      this.departementService.departementList.subscribe((list) => {
        this.departmentList = list as Departement[];
      });
    this.departementService.getDepartementList();

    this.subscriptionSkillList = this.skillService.SkillList.subscribe(
      (list) => {
        this.skillList = list as Skill[];
        if (this.data?.workShift?.SkillId) {
          this.selectedValue = this.skillList.find(
            (x) => x.Id === this.data.workShift.SkillId
          );
        } else {
          this.selectedValue = this.skillList[0];
          this.formModel.SkillId = this.skillList[0]?.Id;
        }
      }
    );
    this.skillService.getSkillList(this.data.workShift.DepartementId);
  }
  getDateHours(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleString([], { hour: '2-digit', minute: '2-digit' });
  }
  onSubmit(form: NgForm): void {
    const startTime = this.hour.StarTime.split(':');
    const endTime = this.hour.EndTime.split(':');
    const startTimeCalendar = this.data.workShift.StarTime;
    this.data.workShift.StarTime = new Date(startTimeCalendar);
    this.data.workShift.StarTime.setHours(startTime[0], startTime[1]);

    this.data.workShift.EndTime = new Date(startTimeCalendar);
    this.data.workShift.EndTime.setHours(endTime[0], endTime[1]);

    if (form.value) {
      form.value.StarTime = this.data.workShift.StarTime;
      form.value.EndTime = this.data.workShift.EndTime;
      if (this.data.workShift.addWorkShift) {
        form.value.EventId = uuidv4();
        this.service.addEvent(form.value);
      } else {
        form.value.EventId = this.data.workShift.EventId;
        form.value.EmployeeId = this.data.workShift.EmployeeId;
        this.service.updateEvent(form.value);
      }

      this.onClose();
    }
  }
  onIconChange(event): void {
    this.selectedValue = this.skillList.find((x) => x.Id === event.value);
  }
  onClose(): void {
    this.dialogRef.close();
  }

  onEmployeeIdChange(form: any): void {
    form.DepartmentId = this.employeeCategory.find(
      (x) => x.Id === form.EmployeeId
    ).DepartmentId;
  }

  ngOnDestroy(): void {
    this.subscriptionDepartmentList.unsubscribe();
    this.subscriptionSkillList.unsubscribe();
  }

  onTimeset(event): void {
    const eventTime = event.split(':');
    this.minEndTime = dayjs()
      .startOf('day')
      .set('h', eventTime[0])
      .set('m', eventTime[1])
      .add(5, 'minutes')
      .format('HH:mm');
  }
}
