import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  Optional,
} from '@angular/core';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { JobType, UserRoles } from 'src/app/core/services/const';
import { AuthService } from 'src/app/modules/auth/services/auth/auth.service';
import { Departement } from 'src/app/modules/departement/model/departement';
import { DepartementService } from 'src/app/modules/departement/services/departement.service';
import { Skill } from 'src/app/modules/skill/model/departement';
import { SkillService } from 'src/app/modules/skill/services/skill.service';
import { EmployeeService } from '../../../employee/services/employee.service';
import { Employee } from '../../model/employee.model';
import { WorkStructure } from 'src/app/modules/settings/model/company-settings.model';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import { OnboardingService } from 'src/app/core/component/onboarding/onboarding.service';
import { OnboardingStep } from 'src/app/core/component/onboarding/onboarding-step.model';

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.css'],
})
export class AddEmployeeComponent implements OnInit, OnDestroy {
  @Input() formModel: Employee = {
    Id: 0,
    FirstName: '',
    LastName: '',
    Gender: 0,
    CivilStatus: 0,
    MobileNo: '',
    HomePhoneNo: '',
    Street1: '',
    City: '',
    ZipCode: '',
    ProfileImage: '',
    DepartmentList: [],
    Department: null,
    WorkingTime: 0,
    Role: 2,
    Email: '',
    Holidays: 0,
    SkillList: [],
    IsActive: true,
    EmailStatus: 0,
    WorkMinutesPerDay: 0,
    WorkDaysPerWeek: 5,
    JobType: JobType.FullTime,
    StartDate: dayjs().add(1, 'month').startOf('month').toDate(),
  };
  @Input() showButtons: boolean = true; // Steuerung der internen Buttons
  @Input() showOnboarding: boolean = false; // Steuerung der Onboarding-Anzeige

  @Output() onSave = new EventEmitter<Employee>(); // Ereignis zum Emittieren der gespeicherten Daten

  @ViewChild('addEmployeeForm') form: NgForm; // Zugriff auf das Formular

  userRoles = UserRoles;
  userRolesList: string[] = [];

  employeeHaseEmail = false;

  hour = {
    hoursPerDay: '8',
  };

  jobTypes = [
    { id: JobType.FullTime, label: this.translate.instant('FULL-TIME') },
    { id: JobType.PartTime, label: this.translate.instant('PART-TIME') },
    { id: JobType.MiniJob, label: this.translate.instant('MINI-JOB') },
  ];
  structure: number = WorkStructure.Departments;
  workStructureEnum = WorkStructure;

  private subscriptionDepartmentList: Subscription;
  private subscriptionSkillList: Subscription;

  departmentList: Departement[] = [];
  JobType = JobType;
  skillList: Skill[] = [];

  userRole = [
    { id: 2, label: this.translate.instant('Employee') },
    { id: 3, label: this.translate.instant('TEAM LEADER') },
  ];

  onboardingSteps: OnboardingStep[] = [
    {
      anchorId: 'first-name-add-employee',
      title: this.translate.instant('First Name'),
      content: this.translate.instant('FIRST_NAME_ONBOARDING_DESCRIPTION'),
      placement: 'left',
    },
    {
      anchorId: 'last-name-add-employee',
      title: this.translate.instant('Last Name'),
      content: this.translate.instant('LAST_NAME_ONBOARDING_DESCRIPTION'),
      placement: 'right',
    },
    {
      anchorId: 'department-add-employee',
      title: this.translate.instant('Department/Project'),
      content: this.translate.instant('DEPARTMENT_ONBOARDING_DESCRIPTION'),
      placement: 'left',
    },
    {
      anchorId: 'skill-add-employee',
      title: this.translate.instant('ABILITY/COMPETENCE'),
      content: this.translate.instant('SKILL_ONBOARDING_DESCRIPTION'),
      placement: 'right',
    },
    {
      anchorId: 'email-add-employee',
      title: this.translate.instant('Email'),
      content: this.translate.instant('EMAIL_ONBOARDING_DESCRIPTION'),
      placement: 'left',
    },
    {
      anchorId: 'role-add-employee',
      title: this.translate.instant('Role'),
      content: this.translate.instant('ROLE_ONBOARDING_DESCRIPTION'),
      placement: 'right',
    },
    {
      anchorId: 'holidays-add-employee',
      title: this.translate.instant('VACATION DAYS'),
      content: this.translate.instant('HOLIDAYS_ONBOARDING_DESCRIPTION'),
      placement: 'left',
    },
    {
      anchorId: 'job-type-add-employee',
      title: this.translate.instant('TYPES OF EMPLOYMENT'),
      content: this.translate.instant('JOB_TYPE_ONBOARDING_DESCRIPTION'),
      placement: 'right',
    },
    {
      anchorId: 'work-days-per-week-add-employee',
      title: this.translate.instant('WORK DAYS PER WEEK'),
      content: this.translate.instant(
        'WORK_DAYS_PER_WEEK_ONBOARDING_DESCRIPTION'
      ),
      placement: 'left',
    },
    {
      anchorId: 'work-hours-per-day-add-employee',
      title: this.translate.instant('WORK HOURS PER DAY'),
      content: this.translate.instant(
        'WORK_HOURS_PER_DAY_ONBOARDING_DESCRIPTION'
      ),
      placement: 'right',
    },
    {
      anchorId: 'start-date-add-employee',
      title: this.translate.instant('EMPLOYMENT START DATE'),
      content: this.translate.instant('START_DATE_ONBOARDING_DESCRIPTION'),
      placement: 'left',
    },
    {
      anchorId: 'working-time-add-employee',
      title: this.translate.instant('WORK HOURS PER WEEK'),
      content: this.translate.instant('WORKING_TIME_ONBOARDING_DESCRIPTION'),
      placement: 'right',
    },
  ];

  departmentValue = new UntypedFormControl();
  skillValue = new UntypedFormControl();
  isOnboardingActive: boolean;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public dialogRef: MatDialogRef<AddEmployeeComponent>,
    public service: EmployeeService,
    public departementService: DepartementService,
    public skillService: SkillService,
    private translate: TranslateService,
    public authService: AuthService,
    public companySettingsService: CompanySettingsService,
    private onboardingService: OnboardingService
  ) {
    this.userRolesList = Object.keys(this.userRoles).filter(
      (f) => !isNaN(Number(f))
    );
    this.structure = this.companySettingsService.CompanySettings.WorkStructure;
  }

  ngOnInit(): void {
    if (this.data && this.data.employee && this.data.employee.Id) {
      if (this.data.employee.StartDate === '0001-01-01T00:00:00') {
        this.data.employee.StartDate = dayjs().toDate();
      }

      this.formModel = {
        ...this.data.employee,
        JobType: this.data.employee.JobType || JobType.Nothing,
        WorkingTime:
          this.data.employee.JobType === JobType.MiniJob
            ? this.data.employee.WorkingHours
            : this.data.employee.WorkingTime,
      };

      // Überprüfen, ob der Mitarbeiter eine gültige E-Mail-Adresse hat
      this.employeeHaseEmail = this.data.employee?.Email?.match(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      )
        ? true
        : false;

      this.hour.hoursPerDay = dayjs
        .utc()
        .startOf('day')
        .add(this.data.employee.WorkMinutesPerDay, 'minutes')
        .format('H');
    }

    // Abonnieren der Department-Liste
    this.subscriptionDepartmentList =
      this.departementService.departementList.subscribe((list) => {
        this.departmentList = list as Departement[];
      });
    this.departementService.getDepartementList();

    // Abonnieren der Skill-Liste
    this.subscriptionSkillList = this.skillService.AllSkillList.subscribe(
      (list) => {
        this.skillList = list as Skill[];
      }
    );
    this.skillService.getAllSkillList();

    if (this.showOnboarding) {
      this.onboardingService.setSteps(this.onboardingSteps);
    }
  }

  toggleOnboarding(): void {
    this.isOnboardingActive = !this.isOnboardingActive;
    if (this.isOnboardingActive) {
      this.onboardingService.setSteps(this.onboardingSteps);
    } else {
      this.onboardingService.removeSteps(this.onboardingSteps);
    }
  }
  onSubmit(form: NgForm): void {
    if (form.valid) {
      // Zerlege die Stunden und Minuten
      const hours = this.hour.hoursPerDay;

      if (hours) {
        // Konvertiere Stunden und Minuten in Gesamtminuten
        this.formModel.WorkMinutesPerDay = dayjs
          .duration({ hours: parseInt(hours), minutes: 0 })
          .asMinutes();

        if (this.formModel.JobType !== JobType.MiniJob) {
          const workInMinutes =
            this.formModel.WorkMinutesPerDay * this.formModel.WorkDaysPerWeek;
          // Konvertiere in Stunden mit 2 Dezimalstellen
          this.formModel.WorkingTime = +(workInMinutes / 60).toFixed(2);
        }

        // Füge den Arbeitgeber hinzu oder aktualisiere ihn
        if (this.formModel.Id === 0) {
          this.service.addEmployer(this.formModel).subscribe({
            next: () => {
              if (this.dialogRef) {
                this.dialogRef.close(this.formModel);
              } else {
                this.onSave.emit(this.formModel);
              }
            },
            error: (error) => {
              console.error('Fehler beim Hinzufügen des Mitarbeiters', error);
            },
          });
        } else {
          this.service.addEmployer(this.formModel).subscribe({
            next: () => {
              this.onSave.emit(this.formModel);
              this.onClose();
            },
            error: (error) => {
              console.error(
                'Fehler beim Aktualisieren des Mitarbeiters',
                error
              );
            },
          });
        }
      }
    }
  }

  onClose(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptionDepartmentList) {
      this.subscriptionDepartmentList.unsubscribe();
    }
    if (this.subscriptionSkillList) {
      this.subscriptionSkillList.unsubscribe();
    }
    this.onboardingService.removeSteps(this.onboardingSteps);
  }

  // Methode zum Speichern, aufrufbar von der Elternkomponente
  save(): void {
    this.onSubmit(this.form);
  }

  // Getter zur Überprüfung der Formvalidität
  get isValid(): boolean {
    return this.form?.valid;
  }
}
