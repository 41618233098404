import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http/http.service';
import { ApiEndPoints, ApiMethod } from 'src/app/core/services/const';
import { BehaviorSubject, Observable, map } from 'rxjs';

export interface Country {
  CountryId: number;
  CountryName: string;
  IsoCountryCode: string;
}

export interface Region {
  RegionId: number;
  RegionName: string;
  IsoRegionCode: string;
  CountryId: number;
}

export interface TimeZone {
  key: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private countriesSource = new BehaviorSubject<Country[]>([]);
  countries$ = this.countriesSource.asObservable();

  private regionsSource = new BehaviorSubject<Region[]>([]);
  regions$ = this.regionsSource.asObservable();

  private timeZonesSource = new BehaviorSubject<TimeZone[]>([]);
  timeZones$ = this.timeZonesSource.asObservable();

  constructor(private httpClient: HttpService) {}

  getAllCountries(): void {
    this.httpClient
      .requestCall(ApiEndPoints.GetAllCountries, ApiMethod.GET)
      .subscribe((countries: Country[]) => {
        this.countriesSource.next(countries);
      });
  }

  getRegionsByCountry(isoCountryCode: string): void {
    this.httpClient
      .requestCall(
        `${ApiEndPoints.GetRegions}?isoCountryCode=${isoCountryCode}`,
        ApiMethod.GET
      )
      .subscribe((regions: Region[]) => {
        this.regionsSource.next(regions);
      });
  }

  getRegionNameByIsoRegionCode(
    isoRegionCode: string
  ): Observable<string | undefined> {
    return this.regions$.pipe(
      map((regions: Region[]) => {
        const region = regions.find((r) => r.IsoRegionCode === isoRegionCode);
        return region ? region.RegionName : undefined;
      })
    );
  }

  // Neue Methode zum Abrufen der Zeitzonen
  getTimeZones(): void {
    const timeZones = [
      { key: 'Africa/Cairo', value: 'Africa/Cairo' },
      { key: 'Africa/Johannesburg', value: 'Africa/Johannesburg' },
      { key: 'Africa/Nairobi', value: 'Africa/Nairobi' },
      { key: 'America/Chicago', value: 'America/Chicago' },
      { key: 'America/Denver', value: 'America/Denver' },
      { key: 'America/Los_Angeles', value: 'America/Los_Angeles' },
      { key: 'America/Sao_Paulo', value: 'America/Sao_Paulo' },
      { key: 'America/Mexico_City', value: 'America/Mexico_City' },
      {
        key: 'America/Argentina/Buenos_Aires',
        value: 'America/Argentina/Buenos_Aires',
      },
      { key: 'Asia/Dubai', value: 'Asia/Dubai' },
      { key: 'Asia/Hong_Kong', value: 'Asia/Hong_Kong' },
      { key: 'Asia/Kolkata', value: 'Asia/Kolkata' },
      { key: 'Asia/Shanghai', value: 'Asia/Shanghai' },
      { key: 'Asia/Tokyo', value: 'Asia/Tokyo' },
      { key: 'Asia/Singapore', value: 'Asia/Singapore' },
      { key: 'Asia/Bangkok', value: 'Asia/Bangkok' },
      { key: 'Australia/Sydney', value: 'Australia/Sydney' },
      { key: 'Australia/Melbourne', value: 'Australia/Melbourne' },
      { key: 'Australia/Brisbane', value: 'Australia/Brisbane' },
      { key: 'Europe/Berlin', value: 'Europe/Berlin' },
      { key: 'Europe/Paris', value: 'Europe/Paris' },
      { key: 'Europe/London', value: 'Europe/London' },
      { key: 'Europe/Moscow', value: 'Europe/Moscow' },
      { key: 'Europe/Rome', value: 'Europe/Rome' },
      { key: 'Europe/Madrid', value: 'Europe/Madrid' },
      { key: 'Europe/Istanbul', value: 'Europe/Istanbul' },
      { key: 'Europe/Kiev', value: 'Europe/Kiev' },
      { key: 'Pacific/Auckland', value: 'Pacific/Auckland' },
      { key: 'Pacific/Honolulu', value: 'Pacific/Honolulu' },
      { key: 'Etc/UTC', value: 'Etc/UTC' },
      { key: 'Etc/GMT', value: 'Etc/GMT' },
      { key: 'Etc/GMT+1', value: 'Etc/GMT+1' },
      { key: 'Etc/GMT-1', value: 'Etc/GMT-1' },
    ];

    this.timeZonesSource.next(timeZones);
  }
}
