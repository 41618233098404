import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval, forkJoin, Observable } from 'rxjs';
import { OpenShiftsService } from 'src/app/modules/admin/services/open-shifts.service';
import { ShiftSwapService } from 'src/app/modules/swap-shift/services/shift-swap.service';
import { CalendarService } from 'src/app/modules/admin/services/calendar.service';
import { TimeRecordingService } from 'src/app/modules/time-recording/services/time-recording.service';
import dayjs from 'dayjs';
import { AbsenceService } from 'src/app/modules/absence/services/absence.service';
import { AvailabilityService } from 'src/app/modules/availability/services/availability.service';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  constructor(
    private swUpdate: SwUpdate,
    private openShiftService: OpenShiftsService,
    private shiftSwapService: ShiftSwapService,
    private calenderServie: CalendarService,
    private timeRecordingService: TimeRecordingService,
    private absenceService: AbsenceService,
    private availabilityService: AvailabilityService
  ) {
    if (swUpdate.isEnabled) {
      interval(6 * 60 * 60 * 1000).subscribe(() =>
        swUpdate.checkForUpdate().then()
      );
    }

    // Überprüfe auf Updates beim Start
    this.checkForUpdates();
  }

  public checkForUpdates(): void {
    this.swUpdate.available.subscribe(() => this.promptUser());
  }

  private promptUser(): void {
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }

  public performRefresh(): Observable<any> {
    // Aktualisiere den aktuellen Timestamp
    this.timeRecordingService.timeRange = {
      StarTime: dayjs().startOf('month').toDate(),
      EndTime: dayjs().toDate(),
    };

    // Führe alle Aktualisierungen parallel aus
    return forkJoin({
      openShifts: this.openShiftService.getOpenShiftsForEmployee(),
      employeeSwapShiftsList:
        this.shiftSwapService.GetEmployeeSwapShiftsListAsync(),
      shiftsForSwapQuery: this.shiftSwapService.GetShiftsForSwapQueryAsync(),
      notAcceptedEvents: this.calenderServie.getNotAcceptedEventFromEmployee(),
      events: this.calenderServie.getEvent(),
      dayWeekMonthStats: this.timeRecordingService.getDayWeekMonthStatsQuery(),
      clockEvents: this.timeRecordingService.getClockEventsForMultipleDays(
        this.timeRecordingService.timeRange.StarTime,
        this.timeRecordingService.timeRange.EndTime
      ),
      absenceEvents: this.absenceService.getAbsenceAsync(),
    });
  }
}
