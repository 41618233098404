<div class="table-responsive">
  <table class="table table-striped">
    <tbody>
      <tr *ngFor="let event of clockEventList; let i = index">
        <!-- Gemeinsamer Container für Icon und Tooltip -->
        <td
          style="width: 5%; text-align: left"
          (click)="showAuditTooltip(i, $event.target)"
        >
          <span
            *ngIf="event.AuditHistory && event.AuditHistory.length > 0"
            class="fa-light fa-alarm-exclamation audit-history"
            style="cursor: pointer"
          ></span>
        </td>

        <td style="width: 20%; text-align: left">
          <span style="padding-left: 15px">{{ event.eventTypeLabel }}</span>
        </td>
        <td style="width: 20%; text-align: left">
          <span *ngIf="!event.editing">{{ event.formattedEventStart }}</span>
          <div *ngIf="event.editing" class="input-icon-container">
            <input
              [(ngModel)]="event.editStartTime"
              class="form-control"
              [format]="24"
              [ngxTimepicker]="with5Gap"
              [ngxMatTimepicker]="pickerStart"
              [min]="minTime"
              (ngModelChange)="validateEventForUpdate(i)"
            />
            <i class="fa fa-clock icon-style" (click)="pickerStart.open()"></i>
          </div>

          <ngx-mat-timepicker
            #pickerStart
            dottedMinutesInGap
          ></ngx-mat-timepicker>
        </td>

        <td style="width: 20%; text-align: left">
          <span *ngIf="!event.editing">{{ event.formattedEventEnd }}</span>
          <div *ngIf="event.editing" class="input-icon-container">
            <input
              [(ngModel)]="event.editEndTime"
              class="form-control"
              [format]="24"
              [ngxTimepicker]="with5Gap"
              [ngxMatTimepicker]="pickerEnd"
              [min]="minTime"
              (ngModelChange)="validateEventForUpdate(i)"
            />
            <i class="fa fa-clock icon-style" (click)="pickerEnd.open()"></i>
          </div>

          <ngx-mat-timepicker
            #pickerEnd
            dottedMinutesInGap
          ></ngx-mat-timepicker>
        </td>
        <td style="width: 35%; text-align: right">
          <button
            style="margin-right: 6px"
            *ngIf="
              !event.editing &&
              event.EventEndId &&
              (authService.isManegeOrTeamLeader ||
                companySettingsService.CompanySettings.TimeTrackingSettings
                  .EmployeeSettings.CanEditTimeRecord)
            "
            (click)="editEvent(i)"
            class="btn btn-sm btn-primary"
          >
            <i class="fa fa-pencil"></i>
          </button>
          <button
            style="margin-right: 6px"
            *ngIf="event.EventEndId === 0 && authService.isManegeOrTeamLeader"
            (click)="stoptTimerForEmployee(i)"
            class="btn btn-sm btn-primary"
          >
            <i class="fa-light fa-play-pause"></i>
          </button>
          <button
            *ngIf="
              !event.editing &&
              (authService.isManegeOrTeamLeader ||
                companySettingsService.CompanySettings.TimeTrackingSettings
                  .EmployeeSettings.CanDeleteTimeRecord)
            "
            (click)="showDeleteDialog(i)"
            class="btn btn-sm btn-danger"
            [disabled]="event.EventEndId === 0"
          >
            <i class="fa-light fa-trash-clock"></i>
          </button>
          <button
            [disabled]="!(validationError$.value === '')"
            [matTooltip]="validationError$.value"
            style="margin-right: 6px"
            *ngIf="event.editing"
            (click)="saveEvent(i)"
            class="btn btn-sm btn-success"
          >
            <i class="fa-light fa-download"></i>
          </button>
          <button
            *ngIf="event.editing"
            (click)="cancelEditEvent(i)"
            class="btn btn-sm btn-danger"
          >
            <i class="fa-light fa-xmark"></i>
          </button>
        </td>
      </tr>
      <tr *ngIf="newEvent">
        <td style="width: 5%"></td>
        <td style="width: 20%">
          <mat-select
            #Role="ngModel"
            name="Role"
            id="Role"
            class="form-control"
            [(ngModel)]="newEvent.EventType"
          >
            <mat-option *ngFor="let role of timeRecordes" [value]="role.id"
              >{{ role.lable }}
            </mat-option>
          </mat-select>
        </td>
        <td style="width: 20%">
          <div class="input-icon-container">
            <input
              [(ngModel)]="newEvent.editStartTime"
              class="form-control"
              [format]="24"
              [ngxTimepicker]="with5Gap"
              [ngxMatTimepicker]="pickerEditStartTime"
              [min]="minTime"
              (ngModelChange)="validateNewEvent()"
            />
            <i
              class="fa fa-clock icon-style"
              (click)="pickerEditStartTime.open()"
            ></i>
          </div>

          <ngx-mat-timepicker
            #pickerEditStartTime
            dottedMinutesInGap
          ></ngx-mat-timepicker>
        </td>
        <td style="width: 20%">
          <div class="input-icon-container">
            <input
              [(ngModel)]="newEvent.editEndTime"
              class="form-control"
              [format]="24"
              [ngxTimepicker]="with5Gap"
              [ngxMatTimepicker]="pickerEditEndTime"
              [min]="minTime"
              (ngModelChange)="validateNewEvent()"
            />
            <i
              class="fa fa-clock icon-style"
              (click)="pickerEditEndTime.open()"
            ></i>
          </div>

          <ngx-mat-timepicker
            #pickerEditEndTime
            dottedMinutesInGap
          ></ngx-mat-timepicker>
        </td>
        <td style="width: 35%; text-align: right">
          <button
            [disabled]="!(validationError$.value === '')"
            [matTooltip]="validationError$.value"
            style="margin-right: 6px"
            (click)="saveNewEvent()"
            class="btn btn-sm btn-success"
          >
            <i class="fa-light fa-download"></i>
          </button>
          <button (click)="cancelNewEvent()" class="btn btn-sm btn-danger">
            <i class="fa fa-times"></i>
          </button>
        </td>
      </tr>
      <tr
        *ngIf="
          !newEvent &&
          (authService.isManegeOrTeamLeader ||
            companySettingsService.CompanySettings.TimeTrackingSettings
              .EmployeeSettings.CanAddTimeRecord)
        "
      >
        <td colspan="3"></td>
        <td colspan="3" style="text-align: right">
          <button
            (click)="addNewEvent()"
            class="btn btn-sm btn-outline-primary"
          >
            <i class="fa fa-plus"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div
    *ngIf="validationError$.value"
    class="alert alert-danger fadeInUpwards"
    role="alert"
  >
    {{ validationError$.value }}
  </div>
</div>
