import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { TimeRecordingService } from '../../services/time-recording.service';
import { TimeDialogComponent } from '../time-dialog/time-dialog.component';

@Component({
  selector: 'app-floating-action-button',
  templateUrl: './floating-action-button.component.html',
  styleUrls: ['./floating-action-button.component.css'],
})
export class FloatingActionButtonComponent implements OnInit, OnDestroy {
  workedTimeDisplay: boolean = false; // Initialwert für Stunden und Minuten
  isComplianceMessages: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private zone: NgZone,
    private dialog: MatDialog,
    private timeRecordingService: TimeRecordingService
  ) {}

  ngOnInit(): void {
    const workedTimeDisplaySub =
      this.timeRecordingService.workedTimeDisplay.subscribe((status) => {
        this.workedTimeDisplay = status;
      });

    const complianceMessagesSub =
      this.timeRecordingService.ComplianceMessage.subscribe((status) => {
        this.isComplianceMessages = status;
      });

    this.subscriptions.push(complianceMessagesSub);
    this.subscriptions.push(workedTimeDisplaySub);

    if (navigator.onLine) {
      this.timeRecordingService.getEmployeeLastStatus();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  handleClick() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false; // Allow closing by clicking outside
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth = '95vw';

    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.position = {
      bottom: '150px', // Adjust based on your FAB position
      right: '20px', // Adjust based on your FAB position
    };
    this.zone.run(() => {
      this.dialog.open(TimeDialogComponent, dialogConfig);
    });
  }
}
