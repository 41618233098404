import { LOCATION_INITIALIZED } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { APP_INITIALIZER, Injectable, Injector, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
} from '@angular/material/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FullCalendarModule } from '@fullcalendar/angular';
import { IonicModule } from '@ionic/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CalendarWeekHorizontalViewComponent } from './core/component/calendar-week-horizontal-view/calendar-week-horizontal-view.component';
import { CalendarWeekListViewComponent } from './core/component/calendar-week-list-view/calendar-week-list-view.component';
import { CalendarWeekViewComponent } from './core/component/calendar-week-view/calendar-week-view.component';
import { ConfirmationDialogInputComponent } from './core/component/confirmation-dialog-input/confirmation-dialog-input.component';
import { ConfirmationDialogComponent } from './core/component/confirmation-dialog/confirmation-dialog.component';
import { InputEditableComponent } from './core/component/input-editable/input-editable.component';
import { ItemListComponent } from './core/component/item-list/item-list.component';
import { MaterialModule } from './core/modules/material/material.module';
import { AutofillDirective } from './core/services/autofill.directive';
import { TrackButtonDirective } from './core/services/matomo/track-button.directive';
import { TextEditableComponent } from './core/services/text-editable.directive';
import { AbsenceCalendarComponent } from './modules/absence/component/absence-calendar/absence-calendar.component';
import { AbsenceHolidayChartComponent } from './modules/absence/component/absence-holiday-chart/absence-holiday-chart.component';
import { AbsenceRequestsComponent } from './modules/absence/component/absence-requests/absence-requests.component';
import { AbsenceSummaryChartComponent } from './modules/absence/component/absence-summary-chart/absence-summary-chart.component';
import { AcceptAbsenceComponent } from './modules/absence/component/accept-absence/accept-absence.component';
import { AddAbsenceComponent } from './modules/absence/component/add-absence/add-absence.component';
import { AbsenceComponent } from './modules/absence/page/absence/absence.component';
import { AddEmployeeInWorkShiftComponent } from './modules/admin/component/add-employee-in-work-shift/add-employee-in-work-shift.component';
import { AddPlanTemplateComponent } from './modules/admin/component/add-plan-template/add-plan-template.component';
import { AddScheduleRequirementComponent } from './modules/admin/component/add-schedule-requirement/add-schedule-requirement.component';
import { EmployeeListCalenderComponent } from './modules/admin/component/employee-list-calender/employee-list-calender.component';
import { OpenWorkShiftListAdminComponent } from './modules/admin/component/open-work-shift-list-admin/open-work-shift-list-admin.component';
import { OpenWorkShiftListComponent } from './modules/admin/component/open-work-shift-list/open-work-shift-list.component';
import { SelectPlanTemplateComponent } from './modules/admin/component/select-plan-template/select-plan-template.component';
import { ShowWorkShiftDetailsComponent } from './modules/admin/component/show-work-shift-details/show-work-shift-details.component';
import { ShowWorkShiftsComponent } from './modules/admin/component/show-work-shifts/show-work-shifts.component';
import { WorkShiftsPlannerCalendarComponent } from './modules/admin/component/work-shifts-planner-calendar/work-shifts-planner-calendar.component';
import { WorkShiftsPlannerTimelineComponent } from './modules/admin/component/work-shifts-planner-timeline/work-shifts-planner-timeline.component';
import { workShiftsTimelineDashboardComponent } from './modules/admin/component/work-shifts-timeline-dashboard/work-shifts-timeline-dashboard.component';
import { DashboardComponent } from './modules/admin/pages/dashboard/dashboard.component';
import { ScheduleRequirementComponent } from './modules/admin/pages/schedule-requirement/schedule-requirement.component';
import { ShiftsPlannerComponent } from './modules/admin/pages/shifts-planner/shifts-planner.component';
import { AcceptInvitationComponent } from './modules/auth/pages/accept-invitation/accept-invitation.component';
import { ChangePasswordComponent } from './modules/auth/pages/change-password/change-password.component';
import { EmailConfirmationComponent } from './modules/auth/pages/email-confirmation/email-confirmation.component';
import { ForbiddenComponent } from './modules/auth/pages/forbidden/forbidden.component';
import { ForgotPasswordComponent } from './modules/auth/pages/forgot-password/forgot-password.component';
import { LoginComponent } from './modules/auth/pages/login/login.component';
import { RegisterUserComponent } from './modules/auth/pages/register-user/register-user.component';
import { RegisteredStepperComponent } from './modules/auth/pages/registered-stepper/registered-stepper.component';
import { ResetPasswordComponent } from './modules/auth/pages/reset-password/reset-password.component';
import { AuthService } from './modules/auth/services/auth/auth.service';
import { AuthInterceptor } from './modules/auth/services/guard/auth.interceptor';
import { AvailabilityPageComponent } from './modules/availability/availability-page/availability-page.component';
import { AddAvailabilityComponent } from './modules/availability/component/add-availability/add-availability.component';
import { AvailabilityPlannerComponent } from './modules/availability/component/availability-planner/availability-planner.component';
import { EmployeeAvailabilityTimelineComponent } from './modules/availability/component/employee-availability-timeline/employee-availability-timeline.component';
import { AddDepartementComponent } from './modules/departement/component/add-departement/add-departement.component';
import { DepartementListComponent } from './modules/departement/component/departement-list/departement-list.component';
import { AddEmployeeComponent } from './modules/employee/component/add-employee/add-employee.component';
import { EmployeeAbsenceCalendarComponent } from './modules/employee/component/employee-absence-calendar/employee-absence-calendar.component';
import { EmployeeListComponent } from './modules/employee/component/employee-list/employee-list.component';
import { EmployeeWeeklyWorkTimeChartComponent } from './modules/employee/component/employee-weekly-work-time-chart/employee-weekly-work-time-chart.component';
import { InviteEmployeeComponent } from './modules/employee/component/invite-employee/invite-employee.component';
import { SearchEmployeeComponent } from './modules/employee/component/search-employee/search-employee.component';
import { ShowEmployeeWorkShiftsComponent } from './modules/employee/component/show-employee-work-shifts/show-employee-work-shifts.component';
import { ShowNotAcceptedEventsComponent } from './modules/employee/component/show-not-accepted-events/show-not-accepted-events.component';
import { EmployeeDashboardComponent } from './modules/employee/page/employee-dashboard/employee-dashboard.component';
import { EmployeeComponent } from './modules/employee/page/employee/employee.component';
import { SubscriptionOptionsComponent } from './modules/feature-toggle/component/change-subscription/subscription-options.component';
import { CurrentSubscriptionComponent } from './modules/feature-toggle/component/current-subscription/current-subscription.component';
import { PaymentConfirmationComponent } from './modules/feature-toggle/component/payment-confirmation/payment-confirmation.component';
import { EmployeeTimeBarChartComponent } from './modules/reports/component/employee-time-bar-chart/employee-time-bar-chart.component';
import { PieChartComponent } from './modules/reports/component/pie-chart/pie-chart.component';
import { ReportFilterComponent } from './modules/reports/component/report-filter/report-filter.component';
import { ReportListComponent } from './modules/reports/component/report-list/report-list.component';
import { TotalWorkHoursReportComponent } from './modules/reports/component/total-work-hours-report/total-work-hours-report.component';
import { ReportsPageComponent } from './modules/reports/reports-page/reports-page.component';
import { ScheduleRequirementPlannerTimelineComponent } from './modules/resource-plan/component/schedule-requirement-planner-timeline/schedule-requirement-planner-timeline.component';
import { ResourcePlanPageComponent } from './modules/resource-plan/resource-plan-page/resource-plan-page.component';
import { CompanySettingsComponent } from './modules/settings/component/company-settings/company-settings.component';
import { ShiftSwapSettingsComponent } from './modules/settings/component/shift-swap-settings/shift-swap-settings.component';
import { TimeTrackingSettingsComponent } from './modules/settings/component/time-recording-settings/time-tracking-settings.component';
import { SettingsPageComponent } from './modules/settings/settings-page/settings-page.component';
import { AddSkillComponent } from './modules/skill/component/add-skill/add-skill.component';
import { SkillListComponent } from './modules/skill/component/skill-list/skill-list.component';
import { AddSwapRequestComponent } from './modules/swap-shift/component/add-swap-request/add-swap-request.component';
import { AdminShowShiftSInSwappingProcessComponent } from './modules/swap-shift/component/admin-show-shifts-in-swapping-process/admin-show-shifts-in-swapping-process.component';
import { ShowMyShiftSwapComponent } from './modules/swap-shift/component/show-my-shift-swap/show-my-shift-swap.component';
import { ShowPossibleWorkShiftForSwapComponent } from './modules/swap-shift/component/show-possible-work-shift-for-swap/show-possible-work-shift-for-swap.component';
import { ShowResponsedWorkShiftForSwapComponent } from './modules/swap-shift/component/show-responsed-work-shift-for-swap/show-responsed-work-shift-for-swap.component';
import { ShowShiftForSwapComponent } from './modules/swap-shift/component/show-shift-for-swap/show-shift-for-swap.component';
import { ShowTargetedSwapShiftComponent } from './modules/swap-shift/component/show-targeted-swap-shift/show-targeted-swap-shift.component';
import { ShowTargetedSwapStatusComponent } from './modules/swap-shift/component/show-targeted-swap-status/show-targeted-swap-status.component';
import { TargetedSwapStatusComponent } from './modules/swap-shift/component/targeted-swap-status/targeted-swap-status.component';
import { AdminTimeRecordingPageComponent } from './modules/time-recording/admin-time-recording-page/admin-time-recording-page.component';
import { AdminTimeRecordingTimelineComponent } from './modules/time-recording/component/admin-time-recording-timeline/admin-time-recording-timeline.component';
import { DayWeekMonthChartComponent } from './modules/time-recording/component/day-week-month-chart/day-week-month-chart.component';
import { EmployeeTimeListComponent } from './modules/time-recording/component/employee-time-list/employee-time-list.component';
import { FloatingActionButtonComponent } from './modules/time-recording/component/floating-action-button/floating-action-button.component';
import { ShowTimeRecordingDetailsComponent } from './modules/time-recording/component/show-time-recording-details/show-time-recording-details.component';
import { TimeDialogComponent } from './modules/time-recording/component/time-dialog/time-dialog.component';
import { TimeRecordingAccordionComponent } from './modules/time-recording/component/time-recording-accordion/time-recording-accordion.component';
import { TimeRecordingDonutChartComponent } from './modules/time-recording/component/time-recording-donut-chart/time-recording-donut-chart.component';
import { TimeRecordingTableComponent } from './modules/time-recording/component/time-recording-table/time-recording-table.component';
import { BottomNavigationEmployeeComponent } from './navigation/bottom-navigation-employee/bottom-navigation-employee.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { MobilePageHeaderComponent } from './navigation/mobile-page-header/mobile-page-header.component';
import { PageHeaderComponent } from './navigation/page-header/page-header.component';
import { LiveStatusComponent } from './modules/admin/component/live-status/live-status.component';
import { ProjectStatusPipe } from './modules/departement/component/departement-list/project-status.pipe';
import { HolidayManagementComponent } from './modules/holiday/component/holiday-management/holiday-management.component';
import { AddCustomHolidayComponent } from './modules/holiday/component/add-custom-holiday/add-custom-holiday.component';
import { OnboardingComponent } from './core/component/onboarding/onboarding.component';
import { OnboardingAnchorDirective } from './core/component/onboarding/onboarding-anchor.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { OnboardingTooltipDirective } from './core/component/onboarding/onboarding-tooltip.directive';
import { OnboardingTooltipComponent } from './core/component/onboarding/onboarding-tooltip.component';
import { ToggleButtonComponent } from './core/component/toggle-button/toggle-button.component';
import { AddCompanyComponent } from './modules/settings/component/add-company/add-company.component';
import { AbsenceAllEmployeeChartComponent } from './modules/absence/component/absence-all-employee-chart/absence-all-employee-chart.component';
import { CustomDateAdapter } from './core/_helpers/custom.date.adapter';
import { EmployeeHourBalanceComponent } from './modules/time-recording/component/employee-hour-balance/employee-hour-balance.component';

@Injectable()
export class MyHammerGestureConfig extends HammerGestureConfig {
  // tslint:disable-next-line:typedef
  buildHammer(element: HTMLElement) {
    return new Hammer(element, {
      touchAction: 'pan-y',
    });
  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    RegisterUserComponent,
    DashboardComponent,
    PageHeaderComponent,
    AddEmployeeComponent,
    EmployeeListComponent,
    EmployeeComponent,
    WorkShiftsPlannerCalendarComponent,
    EmployeeListCalenderComponent,
    AbsenceComponent,
    AddAbsenceComponent,
    AbsenceCalendarComponent,
    AddEmployeeInWorkShiftComponent,
    AddScheduleRequirementComponent,
    CalendarWeekHorizontalViewComponent,
    ForbiddenComponent,
    AcceptInvitationComponent,
    ChangePasswordComponent,
    AbsenceRequestsComponent,
    ShiftsPlannerComponent,
    ScheduleRequirementComponent,
    ShowWorkShiftsComponent,
    AddPlanTemplateComponent,
    SelectPlanTemplateComponent,
    AbsenceHolidayChartComponent,
    AcceptAbsenceComponent,
    ShowWorkShiftDetailsComponent,
    OpenWorkShiftListComponent,
    CompanySettingsComponent,
    ConfirmationDialogComponent,
    ConfirmationDialogInputComponent,
    SearchEmployeeComponent,
    AddDepartementComponent,
    AddSkillComponent,
    SkillListComponent,
    DepartementListComponent,
    WorkShiftsPlannerTimelineComponent,
    ScheduleRequirementPlannerTimelineComponent,
    workShiftsTimelineDashboardComponent,
    AvailabilityPlannerComponent,
    AddAvailabilityComponent,
    EmployeeDashboardComponent,
    ShowEmployeeWorkShiftsComponent,
    EmployeeAbsenceCalendarComponent,
    EmployeeWeeklyWorkTimeChartComponent,
    CalendarWeekViewComponent,
    CalendarWeekListViewComponent,
    OpenWorkShiftListAdminComponent,
    ShowNotAcceptedEventsComponent,
    TextEditableComponent,
    InputEditableComponent,
    ShowMyShiftSwapComponent,
    ShowShiftForSwapComponent,
    ShowPossibleWorkShiftForSwapComponent,
    ShowResponsedWorkShiftForSwapComponent,
    MobilePageHeaderComponent,
    ItemListComponent,
    AutofillDirective,
    EmployeeAvailabilityTimelineComponent,
    AvailabilityPageComponent,
    RegisteredStepperComponent,
    FooterComponent,
    BottomNavigationEmployeeComponent,
    AddSwapRequestComponent,
    AdminShowShiftSInSwappingProcessComponent,
    ShowTargetedSwapStatusComponent,
    TargetedSwapStatusComponent,
    ShowTargetedSwapShiftComponent,
    ShiftSwapSettingsComponent,
    SettingsPageComponent,
    ResourcePlanPageComponent,
    EmailConfirmationComponent,
    FloatingActionButtonComponent,
    TimeDialogComponent,
    TimeRecordingDonutChartComponent,
    DayWeekMonthChartComponent,
    AdminTimeRecordingTimelineComponent,
    AdminTimeRecordingPageComponent,
    ShowTimeRecordingDetailsComponent,
    TimeRecordingTableComponent,
    TimeRecordingAccordionComponent,
    TimeTrackingSettingsComponent,
    EmployeeTimeListComponent,
    ReportsPageComponent,
    ReportListComponent,
    ReportFilterComponent,
    EmployeeTimeBarChartComponent,
    TotalWorkHoursReportComponent,
    PieChartComponent,
    InviteEmployeeComponent,
    TrackButtonDirective,
    AbsenceSummaryChartComponent,
    CurrentSubscriptionComponent,
    SubscriptionOptionsComponent,
    PaymentConfirmationComponent,
    LiveStatusComponent,
    ProjectStatusPipe,
    HolidayManagementComponent,
    AddCustomHolidayComponent,
    OnboardingComponent,
    OnboardingAnchorDirective,
    OnboardingTooltipDirective,
    OnboardingTooltipComponent,
    ToggleButtonComponent,
    AddCompanyComponent,
    AbsenceAllEmployeeChartComponent,
    EmployeeHourBalanceComponent,
  ],
  imports: [
    NgxMatTimepickerModule,
    FullCalendarModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    OverlayModule,
    MaterialModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgxMatSelectSearchModule,
    IonicModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    HammerModule,
    NgbModule,
    NgxMatomoTrackerModule.forRoot({
      trackerUrl: environment.matomo.url,
      siteId: environment.matomo.siteId,
    }),
    NgxMatomoRouterModule.forRoot(), // Automatisches Pageview-Tracking
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerGestureConfig },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],
  bootstrap: [AppComponent],
  entryComponents: [OnboardingTooltipComponent], // Für dynamische Komponenten
})
export class AppModule {}

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector
): () => Promise<any> {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        const userLanguage = navigator.language.split('-');
        const langToSet = localStorage.getItem('lang') || userLanguage[0];
        translate.setDefaultLang(langToSet);

        translate.use(langToSet).subscribe({
          next: () => {
            resolve(null);
          },
          error: (err) => {
            console.error(
              `Problem with '${langToSet}' language initialization.'`
            );
            resolve(null);
          },
        });
      });
    });
}
