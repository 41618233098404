import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/core/services/http/http.service';
import { ApiEndPoints, ApiMethod } from 'src/app/core/services/const';

export interface CompanyDetails {
  IsoCountryCode: any;
  IsoRegionCode: null;
  TimeZone: any;
  CompanyId: number;
  Name: string;
  TaxId: string;
  PhoneNumber: string;
  CompanyTypeId?: number;
  CompanyTypeName?: string;
  Address?: Address;
}

export interface Address {
  Street: string;
  HouseNumber: string;
  PostalCode: string;
  City: string;
  Country: string;
}

export interface CompanyTypeDto {
  Id: number;
  Name: string;
}

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private httpClient: HttpService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  private companyDetailsSource: BehaviorSubject<CompanyDetails> =
    new BehaviorSubject(null);
  companyDetails$ = this.companyDetailsSource.asObservable();

  private companyTypesSource: BehaviorSubject<CompanyTypeDto[]> =
    new BehaviorSubject([]);
  companyTypes$ = this.companyTypesSource.asObservable();

  getCompanyDetails(): void {
    this.httpClient
      .requestCall(ApiEndPoints.GetCompanyDetails, ApiMethod.GET)
      .subscribe((res: CompanyDetails) => {
        this.companyDetailsSource.next(res);
      });
  }

  updateCompany(company: CompanyDetails): Observable<any> {
    return this.httpClient
      .requestCall(ApiEndPoints.UpdateCompany, ApiMethod.POST, company)
      .pipe(
        tap((res: any) => {
          if (res) {
            this.toastr.success(
              this.translateService.instant('COMPANY UPDATED SUCCESSFULLY'),
              this.translateService.instant('UPDATE COMPANY')
            );
          } else {
            this.toastr.error(
              this.translateService.instant('COMPANY UPDATE FAILED'),
              this.translateService.instant('UPDATE COMPANY')
            );
          }
          // Refresh company details after update
          this.getCompanyDetails();
        })
      );
  }

  getCompanyTypes(): void {
    this.httpClient
      .requestCall(ApiEndPoints.GetCompanyTypes, ApiMethod.GET)
      .subscribe((res: CompanyTypeDto[]) => {
        this.companyTypesSource.next(res);
      });
  }
}
