import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { color, EChartsOption } from 'echarts';
import { TimeChart } from '../../model/time-chart';

@Component({
  selector: 'app-time-recording-donut-chart',
  templateUrl: './time-recording-donut-chart.component.html',
  styleUrls: ['./time-recording-donut-chart.component.css'],
})
export class TimeRecordingDonutChartComponent implements OnInit, OnChanges {
  chartOption: EChartsOption;
  @Input() timeChart: TimeChart;
  constructor() {}

  ngOnInit(): void {
    this.updateChartOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.timeChart) {
      this.updateChartOptions();
    }
  }

  updateChartOptions(): void {
    const series: any[] = [];
    const gaugeData = [];
    const colorPalette = [];
    const primaryColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--primary-color-blue')
      .trim();

    if (this.timeChart.Percentage >= 0) {
      gaugeData.push({
        value: this.timeChart.Percentage,
        detail: {
          valueAnimation: true,
          animationDuration: 16000,
          animationEasing: 'cubicOut',
          offsetCenter: ['0%', '-50%'],
        },
      });
      colorPalette.push(primaryColor);

      series.push({
        radius: '100%',
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false,
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            borderWidth: 1,
            borderColor: '#fff',
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [
                { offset: 0, color: '#3f51b5' },
                { offset: 1, color: '#1e88e5' },
              ],
            },
          },
        },
        axisLine: {
          lineStyle: {
            width: 7,
          },
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
          distance: 50,
        },
        data: gaugeData,
        detail: {
          width: 50,
          height: 10,
          fontSize: 12,
          borderColor: 'auto',
          formatter: () => {
            return `{bold|${this.timeChart.LabelText}}\n{gray|${this.timeChart.SecondLabelText}}`;
          },
          rich: {
            bold: {
              fontWeight: 'bold',
              color: '#3f51b5',
            },
            gray: {
              color: 'gray',
            },
            offsetCenter: ['0%', '-15%'],
          },
          lineHeight: 19,
        },
      });
    }

    if (this.timeChart.OvertimePercentage > 0) {
      series.push({
        radius: '88%',
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false,
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            borderWidth: 1,
            borderColor: '#fff',
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [
                { offset: 0, color: '#80b0fe' },
                { offset: 1, color: '#a7e0fb' },
              ],
            },
          },
        },
        axisLine: {
          lineStyle: {
            width: 7,
          },
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
          distance: 50,
        },
        data: [
          {
            value: this.timeChart.OvertimePercentage,
            detail: {
              valueAnimation: true,
              fontSize: 12,
              formatter: () => {
                return `${this.timeChart.OvertimeLabelText || ''}`;
              },
              offsetCenter: ['0%', '50%'],
              lineHeight: 10,
              // Andere Farbe für die Überstunden, z. B. ein dunkler Rotton:
              color: '#80b0fe',
            },
          },
        ],
        detail: { show: true },
      });
    }

    this.chartOption = {
      color: colorPalette,
      grid: {
        left: 0,
        top: 30,
        right: 0,
        bottom: 0,
      },
      series: series,
    };
  }

  updateChartOptions2(): void {
    const series: any[] = [];

    const primaryGradient = {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 1,
      y2: 0,
      colorStops: [
        { offset: 0, color: '#3cba92' },
        { offset: 1, color: '#0ba360' },
      ],
    };

    const overtimeGradient = {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 1,
      y2: 0,
      colorStops: [
        { offset: 0, color: '#ff7f50' },
        { offset: 1, color: '#f94d6a' },
      ],
    };

    // Basis-Gauge für reguläre Arbeitsstunden
    if (this.timeChart.Percentage >= 0) {
      series.push({
        name: 'Work',
        radius: '100%',
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: { show: false },
        axisLine: {
          lineStyle: {
            width: 2, // Dünnere Linie für die grüne Anzeige
            color: [[1, '#ddd']],
          },
        },
        progress: {
          show: true,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: primaryGradient,
          },
        },
        axisTick: { show: false },
        splitLine: { show: false },
        axisLabel: { show: false },
        data: [
          {
            value: this.timeChart.Percentage,
            detail: {
              valueAnimation: true,
              fontSize: 10,
              formatter: () => {
                return `${this.timeChart.LabelText}\n${this.timeChart.SecondLabelText}`;
              },
              offsetCenter: ['0%', '30%'],
              lineHeight: 10,
              // Farbe hier z. B. schwarz lassen für reguläre Arbeitsstunden
              color: '#000',
            },
          },
        ],
        detail: { show: true },
      });
    }

    // Zweite Gauge für Überstunden
    if (
      this.timeChart.OvertimePercentage != null &&
      this.timeChart.OvertimePercentage >= 0
    ) {
      series.push({
        name: 'Overtime',
        radius: '70%',
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: { show: false },
        axisLine: {
          lineStyle: { width: 2, color: [[1, '#ddd']] }, // gleiche dünne Linie
        },
        progress: {
          show: true,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: overtimeGradient,
          },
        },
        axisTick: { show: false },
        splitLine: { show: false },
        axisLabel: { show: false },
        data: [
          {
            value: this.timeChart.OvertimePercentage,
            detail: {
              valueAnimation: true,
              fontSize: 10,
              formatter: () => {
                return `${this.timeChart.OvertimeLabelText || ''}`;
              },
              offsetCenter: ['0%', '30%'],
              lineHeight: 10,
              // Andere Farbe für die Überstunden, z. B. ein dunkler Rotton:
              color: '#f94d6a',
            },
          },
        ],
        detail: { show: true },
      });
    }

    this.chartOption = {
      series: series,
    };
  }
}
