import {
  Component,
  OnInit,
  Optional,
  Inject,
  Input,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CompanyService,
  CompanyDetails,
  CompanyTypeDto,
} from '../../services/company.service';
import {
  LocationService,
  TimeZone,
  Country,
  Region,
} from '../../services/location.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CompanySettingsService } from '../../services/company-settings.service';
import { CompanySettings } from '../../model/company-settings.model';
import { OnboardingService } from 'src/app/core/component/onboarding/onboarding.service';
import { OnboardingStep } from 'src/app/core/component/onboarding/onboarding-step.model';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.css'],
})
export class AddCompanyComponent implements OnInit {
  @Input() showOnboarding: boolean = false; // Steuerung der Onboarding-Anzeige
  isOnboardingActive: boolean = false;

  @Input() showButtons: boolean = true; // Steuerung der internen Buttons

  @Output() onSave = new EventEmitter<AddCompanyComponent>(); // Ereignis zum Emittieren der gespeicherten Daten

  @ViewChild('companyForm') form: NgForm;

  companyDetails: CompanyDetails = {
    CompanyId: 0,
    Name: '',
    TaxId: '',
    PhoneNumber: '',
    CompanyTypeId: null,
    Address: {
      Street: '',
      HouseNumber: '',
      PostalCode: '',
      City: '',
      Country: '',
    },
    TimeZone: '',
    IsoCountryCode: '',
    IsoRegionCode: null,
  };

  companySettings: CompanySettings = new CompanySettings();

  companyTypes: CompanyTypeDto[] = [];
  timeZones: { key: string; value: string }[] = [];
  countries: { key: string; value: string }[] = [];
  regions: { key: string; value: string }[] = [];
  isoCountryCode: string = null;

  // Definition der Onboarding-Schritte (Tooltips)
  onboardingSteps: OnboardingStep[] = [
    {
      anchorId: 'name-add-company',
      title: this.translateService.instant('Company Name'),
      content: this.translateService.instant('COMPANY_NAME_TOOLTIP'),
      placement: 'left',
    },
    {
      anchorId: 'company-type-add-company',
      title: this.translateService.instant('Company Type'),
      content: this.translateService.instant('COMPANY_TYPE_TOOLTIP'),
      placement: 'right',
    },
    {
      anchorId: 'phone-number-add-company',
      title: this.translateService.instant('Phone Number'),
      content: this.translateService.instant('PHONE_NUMBER_TOOLTIP'),
      placement: 'left',
    },
    {
      anchorId: 'time-zone-add-company',
      title: this.translateService.instant('TIME ZONE'),
      content: this.translateService.instant('TIME_ZONE_TOOLTIP'),
      placement: 'right',
    },
    {
      anchorId: 'country-add-company',
      title: this.translateService.instant('COUNTRY'),
      content: this.translateService.instant('COUNTRY_TOOLTIP'),
      placement: 'left',
    },
    {
      anchorId: 'region-add-company',
      title: this.translateService.instant('REGION/STATE'),
      content: this.translateService.instant('REGION_TOOLTIP'),
      placement: 'right',
    },
    // Weitere Felder können bei Bedarf hinzugefügt werden
  ];

  constructor(
    private companyService: CompanyService,
    private locationService: LocationService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional()
    public dialogRef: MatDialogRef<AddCompanyComponent>,
    private companySetting: CompanySettingsService,
    private onboardingService: OnboardingService
  ) {}

  ngOnInit(): void {
    this.companySetting.CompanySettingsObservable.subscribe((settings) => {
      if (settings) {
        this.companySettings = settings;
      }
    });
    this.companySetting.getCompanySettings();

    this.locationService.regions$.subscribe((regions) => {
      this.regions = regions.map((region) => {
        return { key: region.IsoRegionCode, value: region.RegionName };
      });
    });

    this.locationService.getRegionsByCountry(
      this.companySettings.IsoCountryCode
    );

    this.loadCompanyDetails();
    this.loadCompanyTypes();
    this.loadTimeZones();
    this.loadCountries();

    if (this.showOnboarding) {
      this.isOnboardingActive = true;
      this.onboardingService.setSteps(this.onboardingSteps);
    }
  }

  toggleOnboarding(): void {
    this.isOnboardingActive = !this.isOnboardingActive;
    if (this.isOnboardingActive) {
      this.onboardingService.setSteps(this.onboardingSteps);
    } else {
      this.onboardingService.removeSteps(this.onboardingSteps);
    }
  }

  loadCompanyDetails() {
    this.companyService.companyDetails$.subscribe((data) => {
      if (data) {
        this.companyDetails = data;

        if (this.companyDetails.IsoCountryCode) {
          this.onCountryChange();
        }
      }
    });
    this.companyService.getCompanyDetails();
  }

  loadCompanyTypes() {
    this.companyService.companyTypes$.subscribe((types) => {
      this.companyTypes = types;
    });
    this.companyService.getCompanyTypes();
  }

  loadTimeZones() {
    this.locationService.getTimeZones();
    this.locationService.timeZones$.subscribe((timeZones) => {
      this.timeZones = timeZones.map((tz) => {
        return { key: tz.key, value: tz.value };
      });
    });
  }

  loadCountries() {
    this.locationService.getAllCountries();
    this.locationService.countries$.subscribe((countries: Country[]) => {
      this.countries = countries.map((country) => ({
        key: country.IsoCountryCode,
        value: country.CountryName,
      }));
    });
  }

  onCountryChange(): void {
    this.isoCountryCode = this.companySettings.IsoCountryCode;
    if (this.isoCountryCode) {
      this.locationService.getRegionsByCountry(this.isoCountryCode);
      this.locationService.regions$.subscribe((regions) => {
        this.regions = regions.map((region) => ({
          key: region.IsoRegionCode,
          value: region.RegionName,
        }));
      });
    } else {
      this.regions = [];
    }
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      this.companySetting.updateCompanySettings(this.companySettings);
      this.companyService
        .updateCompany(this.companyDetails)
        .subscribe((result) => {
          if (result) {
            if (this.dialogRef) {
              // Wenn als Dialog verwendet, schließen und Daten zurückgeben
              this.dialogRef.close(this.companyDetails);
            } else {
              // Wenn eingebettet, das onSave Ereignis emittieren
              this.onSave.emit(this);
            }
          } else {
            this.toastr.error(
              this.translateService.instant('COMPANY UPDATE FAILED'),
              this.translateService.instant('UPDATE COMPANY')
            );
          }
        });
    }
  }

  onCancel() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  save(): void {
    this.onSubmit(this.form);
  }
}
