import { Component, OnInit } from '@angular/core';
import { EmployeeListByDateDto } from 'src/app/modules/admin/model/department-dto.model';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { EmployeeService } from 'src/app/modules/employee/services/employee.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-absence-all-employee-chart',
  templateUrl: './absence-all-employee-chart.component.html',
  styleUrls: ['./absence-all-employee-chart.component.css'],
})
export class AbsenceAllEmployeeChartComponent implements OnInit {
  public filteredEmployees: ReplaySubject<EmployeeListByDateDto[]> =
    new ReplaySubject<EmployeeListByDateDto[]>(1);

  public employeeFilterCtrl: FormControl = new FormControl();

  protected _onDestroy = new Subject<void>();
  public filterForm: FormGroup;

  public employeeList: EmployeeListByDateDto[] = [];
  constructor(private employeeService: EmployeeService) {}

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      employee: new FormControl(-1),
    });

    this.employeeService.employeeNameList.subscribe((list) => {
      this.employeeList = list as EmployeeListByDateDto[];

      // remove the duplicate employee id
      this.employeeList = this.employeeList.filter(
        (v, i, a) => a.findIndex((t) => t.Id === v.Id) === i
      );

      this.filteredEmployees.next(this.employeeList.slice());
    });

    this.employeeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterEmployees();
      });

    this.employeeService.getEmployeeNameList();
  }

  filterEmployees(): void {
    if (!this.employeeList) {
      return;
    }
    let search = this.employeeFilterCtrl.value;
    if (!search) {
      this.filteredEmployees.next(this.employeeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredEmployees.next(
      this.employeeList.filter((employee) =>
        employee.Name.toLowerCase().includes(search)
      )
    );
  }

  onInputChange(field: string): void {
    console.log(this.filterForm.value.employee);
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
