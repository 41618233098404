<div *ngFor="let request of absenceRequests" class="event-animation-scale">
  <app-item-list
    [Title]="request.Title"
    [Content]="request.dateString"
    [ContentColor]="request.Color"
    [Image]="
      request.AbsenceType === 'Sick'
        ? './assets/icons/sick.png'
        : './assets/icons/holiday.png'
    "
    [buttonClass]="'btn btn-outline-success'"
    [BadgeContent]="request.AbsenceType | translate"
    (onClick)="acceptRequest(request)"
  ></app-item-list>
</div>
